.image-advert-wrapper, .video-box{
    position:relative;
    margin-bottom: 50px;
    display:block;
    height:0;
    padding-bottom:72%;
    width:100%;

    img{
        position:absolute;
        height:100%;
        width:100%;
        object-fit: cover;
    }

    &:hover{
        img,video{
            filter:brightness(105%)
        }
        .cta-text{
            transform: translate(-50%, calc(50% - 0.5rem));
        }
    }


    .cta-text{
        background:$newGold;
        color:black;
        font-weight:700;
        text-transform:uppercase;
        padding: 15px 20px;
        transition:0.3s;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        text-align:center;
    }
}

.text-advert-wrapper {
    position:relative;
    margin-bottom: 50px;
    display:block;
    height:0;
    width:100%;
    background-color: #172643;
    padding: 45px 30px 40px 50px;
    color:white;
    min-height:390px !important;
}


.blog{
    .image-advert-wrapper, .video-box, .ad-wrapper {
        margin-bottom: 103px !important;
    }
}
