.custom-flexible-section{
    &.match-section{
        display:flex;
        background-position:top;
        background-size:cover;


        @media(max-width: 992px) {
            flex-direction: column;
        }

        #countdown-timer{
            display:flex;
            text-transform:uppercase;
            font-weight:800;
            justify-content: center;
            .value-wrapper{
                margin:0 20px;
                .value{
                    color:$newLightBlue;
                    font-size:24px;
                }
                .title{
                    font-size:17px;
                }
            }

        }

        .ctas{
            display:flex;
            margin-top:15px;
            justify-content: center;
            .cta{
                text-transform:uppercase;
                text-align:center;
                font-weight:700;
                margin:0 12.5px;
                a{
                    color:black;
                    background:$newGold;
                    padding:10px 20px;
                    display:block;
                    transition:0.3s;
                    &:hover{
                        text-decoration: none;
                        opacity:1;
                        background: #ffc658;
                    }
                }
            }
        }


        .matches-holder{
            background:rgba(#06222B, 0.3);
            width:50%;
            text-align:center;
            padding:72px 48px;

            @media(max-width: 1350px){
                padding:72px 15px;
            }

            @media(max-width: 992px){
                width:100%;
            }

            h2{
                color:$newLightBlue;
                margin:0;
                background:none;
                font-weight:800;
            }
        }

        .next-match{
            margin-bottom:30px;

            h2{
                font-family:"Zooja", sans-serif;
                text-transform:uppercase;
                font-size: 60px;
                @media(min-width:420px) {
                    font-size: 80px;
                }
                @media(min-width:1200px) {
                    font-size: 120px;
                }
            }

            .details-wrapper{
                display:flex;
                justify-content: center;
                align-items: center;
                margin:30px 0;

                .logo{
                    height:144px;
                    width:144px;
                    margin:0 30px;
                    display:flex;
                    align-items: center;
                    justify-content: center;

                    @media(max-width:1350px){
                        height:85px;
                        width:85px;
                        margin:0 15px;
                    }

                    @media(max-width:576px){
                        height:70px;
                        width:70px;
                        margin:0 10px;
                    }

                    img{
                        max-width:100%;
                        max-height:100%;
                        height:auto;
                        width:auto;
                    }
                }

                .details{
                    font-weight:700;
                    color:$white;



                    .time{
                        font-size:24px;
                        margin-bottom:10px;
                        color:$newLightBlue;
                        @media(max-width:576px){
                            font-size:16px;
                        }
                    }
                    .stadium{
                        font-size:20px;
                        @media(max-width:576px){
                            font-size:16px;
                        }
                    }
                    .date{
                        font-size:20px;
                        margin-bottom:15px;
                        @media(max-width:576px){
                            font-size:16px;
                        }
                    }
                    .competition{
                        font-size:18px;
                        color:$newLightBlue;
                        @media(max-width:576px){
                            font-size:16px;
                        }
                    }
                }
            }
        }
        .last-match{
            background:transparent;
            margin:0 auto;

            h2{
                color:$white;
                margin-bottom:15px;
            }

            .score-wrapper{
                display:flex;
                align-items: center;
                justify-content: center;
                .holder{
                    display:flex;
                    align-items: center;

                    &.home.order-1{
                        .score{
                            &:after{
                                content:"-";
                                margin:0 5px;
                            }
                        }
                    }
                    &.home.order-2{
                        .score{
                            &:before{
                                content:"-";
                                margin:0 5px;
                            }
                        }
                    }

                    .score{
                        font-weight:800;
                        color:$newLightBlue;
                        font-size:24px;
                    }
                    .logo{
                        height:72px;
                        width:72px;
                        margin:0 15px;
                        display:flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            max-width:100%;
                            max-height:100%;
                            height:auto;
                            width:auto;
                        }
                    }
                }
            }
        }

        .flexible-league-table{
            width:50%;
            display:flex;
            align-items: center;
            justify-content: center;
            padding:48px 0;

            @media(max-width: 992px){
                width:100%;
            }

            table{
                border-collapse:separate;
                border-spacing:15px;
                width: 70%;

                @media(max-width:1500px){
                    width:80%;
                }

                @media(max-width:1350px){
                    width:90%;
                }

                @media(max-width:576px){
                    width:98%;
                }

                td{
                    background:$white;
                    padding: 10px;
                    @media(max-width:1200px) {
                        padding: 5px;
                    }

                    color:$newMainBlue;
                    &.number{
                        text-align:center;
                        font-size:22px;
                        font-weight:700;
                        width:68px;
                        @media(max-width:1200px){
                            font-size:18px;
                            width:58px;
                        }
                        @media(max-width:576px){
                            font-size:16px;
                        }
                        @media(max-width:576px) {
                            padding: 5px 0;
                        }
                    }
                    &.team{
                        font-size:22px;
                        font-weight:700;
                        @media(max-width:1200px){
                            font-size:18px;
                        }
                        @media(max-width:576px){
                            font-size:16px;
                        }
                        img{
                            max-width:48px;
                            height:auto;
                            margin-right:10px;
                            @media(max-width:576px){
                                margin-right:5px;
                            }
                        }
                    }
                }
                th{
                    color:$white;
                }

                tr{
                    &.highlighted{
                        td{
                            background:rgba($white, 0.6);
                        }
                    }
                }

            }


        }

    }
}
