.custom-sponsors{
    background:$white;
    position:relative;

    .sponsor-wrapper{
        padding:32px 5%;
        @media(min-width:1024px) {
            padding: 32px 10%;
        }
        @media(min-width:1400px) {
            padding: 32px 15%;
        }
    }

    img{
        margin-bottom:2rem;
    }

    .main-sponsor{
        display: flex;
        justify-content: center;
    }

    .secondary-sponsors{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        
        .secondary-sponsor{
            width: 50%;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;

            @media(min-width:576px) {
                width: 33%;
            }
            @media(min-width:1024px) {
                width: 25%;
            }
            @media(min-width:1400px) {
                width: 20%;
            }
        }
    }

    h2{
        text-align:center;
        font-size:24px;
        text-transform:uppercase;
        font-weight:800;
        margin-bottom:2rem;
    }
}