.custom-flexible-sections {
    &.community-section {
        background-size:cover;
        background-repeat:no-repeat;

        .top-section{
            text-align:center;
            color:$newLightBlue;
            padding-top:48px;
            .primary-title{
                margin-top:0;
                margin-bottom:0;
                font-size:48px;
                text-transform:uppercase;
                font-weight:800;
            }

            .title-override{
                img{
                    max-width:100%;
                    height:auto;
                }
            }

            .secondary-title {
                text-transform:uppercase;
                margin-top:0;
                font-family:"Zooja", sans-serif;
                font-size:60px;
                @media(min-width:576px){
                    font-size:80px;
                }
                @media(min-width:992px){
                    font-size:120px;
                }

            }
        }

        .advert-grid{

            display:grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 32px;
            grid-row-gap:64px;
            padding:48px 0 96px 0;

            @media(max-width:992px){
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
            }

            @media(max-width:625px){
                grid-template-columns: 1fr;
                grid-template-rows: repeat(5, 1fr);
            }

            .advert-item{
                position:relative;

                a{
                    display:block;
                    transition:0.3s;
                    &:hover{
                        filter:brightness(105%);
                        .cta-text{
                            opacity:1;
                            background: #ffc658;
                            transform: translate(-50%, calc(50% - 0.5rem));
                        }

                    }
                }

                &:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
                &:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
                &:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
                &:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
                &:nth-child(5) { grid-area: 1 / 3 / 3 / 4; }

                @media(max-width:992px){
                    &:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
                    &:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
                    &:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
                    &:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
                    &:nth-child(5) { grid-area: 3 / 1 / 4 / 3; }
                }

                @media(max-width:625px){
                    &:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
                    &:nth-child(2) { grid-area: 2 / 1 / 3 / 2; }
                    &:nth-child(3) { grid-area: 3 / 1 / 4 / 2; }
                    &:nth-child(4) { grid-area: 4 / 1 / 5 / 2; }
                    &:nth-child(5) { grid-area: 5 / 1 / 6 / 2; }
                }

            }

            .image-wrapper,.video-wrapper{
                height:0;
                padding-bottom:70%;
                position:relative;
                img,video,iframe{
                    height:100%;
                    width:100%;
                    top:0;
                    left:0;
                    position:absolute;
                }
                video,iframe{
                    object-fit: cover;
                }
            }

            .twitter-feed{
                height:100%;
                width:100%;
                .iframe{
                    height:100%;
                }
            }

            .cta-text{
                width: fit-content;
                background: $newGold;
                color: black;
                font-weight: 700;
                text-transform: uppercase;
                padding: 15px 20px;
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%,50%);
                text-align: center;
                transition:0.3s;
            }

        }

    }
}
