.custom-newsletter{
    position:relative;
    background:$newMidBlue;
    color:$white;

    img.newsletter-bg{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .custom-wrapper {
        padding-top:48px;
        padding-bottom:48px;
    }

    .box-wrapper{
        background:rgba(#081c40, 0.8);
        padding:48px 5%;
        position:relative;
        z-index:10;
        h2{
            text-transform:uppercase;
            text-align:center;
            font-weight:800;
            font-size: 40px;
        }

        .description{
            text-align:center;
            margin-top:20px;
            margin-bottom:20px;
        }

        .news-button-wrapper {
            display:flex;
            justify-content: center;

            @media(max-width:576px) {
                flex-direction: column;
                align-items: center;
            }

            .newsletter-button {
                text-transform:uppercase;
                border:none;
                display:block;
                margin:2rem;
                padding:1rem 3rem;
                text-align: center;
                font-size:20px;
                font-weight:800;
                width:300px;
                max-width:100%;

                &.theme {
                    background:#7ae1fe;
                    color:black;

                    &:hover{
                        background:transparent;
                        color:white;
                        border-color: white;
                        border-style: solid;
                        border-width: 1px;
                    }
                }

                &.transparent {
                    background:transparent;
                    color:white;
                    border-color: white;
                    border-style: solid;
                    border-width: 1px;

                    &:hover{
                        background:#7ae1fe;
                        color:black;
                        border-color: #7ae1fe;
                        border-style: solid;
                    }
                }
            }
        }

        .disclaimer{
            max-width: 90%;
            @media(min-width:576px) {
                max-width: 60%;
            }
            font-size:14px;
            margin:32px auto 0 auto;
            text-align:center;
            p{
                margin-bottom:0;
            }
            a{
                color:#FFF;
            }
        }
    }
}