@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import '~swiper/css/swiper.min.css';

@import "common/global";
@import "components/advert";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/grid-xl-col";
@import "components/wp-classes";
@import "components/rob_edits";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/ie";
@import "layouts/matches";
@import "layouts/team";
@import "components/membership";
@import "flexible-sections/hero-section";
@import "flexible-sections/news-carousel-section";
@import "flexible-sections/featured-news-section";
@import "flexible-sections/match-section";
@import "flexible-sections/banner-cta-section";
@import "flexible-sections/image-banner-section";
@import "flexible-sections/community-section";
@import "flexible-sections/sponsors-section";
@import "flexible-sections/newsletter-section";
@import "flexible-sections/community_section_with_socials";