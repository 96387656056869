.parent-link {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}

.parent-link {
	text-decoration:none;
}

.parent-link:before,
.parent-link:after {
	content:'';
    position: absolute !important;
    width: 100% !important;
    height: 1px !important;
    background: #3DBFEF !important;
    top: 100% !important;
    left: 0 !important;
    pointer-events: none;
}

.parent-link:before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.parent-link:hover:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.parent-link:after {
    content: '';
    top: calc(100% + 4px) !important;
    transform-origin: 0% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    display: inline !important;
}

.parent-link:hover:after {
    transform-origin: 100% 50%;
    transform: scale3d(1, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
}

.custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper img {
	object-fit:unset;
	height:auto;
}

.hero-section:before, .post-type-archive-match:before, .page-template-template-taxonomy_team:before {
	content:'';
	background-image:url('https://d1ssu070pg2v9i.cloudfront.net/2022/02/10170049/mobile-100-bg.png');
	background-image:url('https://dundeefc.co.uk/wp-content/uploads/2022/02/mobile-100-bg.png');
  position: fixed;
	left:0;
	right:0;
	bottom:0;
  top: 0;
  z-index: -1;
  max-width: 100%;
  width: 100%;
	background-size:contain;
	background-repeat:no-repeat;
	opacity:0.6;
}

.hero-section .banner-100, .post-type-archive-match .top-banner-100, .page-template-template-taxonomy_team .top-banner-100 {
	display:none;
}

.top-banner-100 {
	top:0;
}

.single-post .single-post-holder .standard-post-output .entry-content {
    background: rgba(255,255,255,0.8);
}

.advert-section {
	background-position:bottom;
}

.custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper {
	padding-top: calc(750 / 1200 * 100%);
}

.standard-post .image-wrapper {
	padding-bottom: 65%;
}

.custom-flexible-section.featured-news-section .article-wrapper .image-wrapper img.overlay {
    object-fit: contain;
    height: auto;
}

.archive.category .top-section, .blog .top-section {
	background-size:contain;
	background-position:left;
}

.custom-flexible-sections.community-section .advert-grid .image-wrapper img {
	object-fit: cover;
}   

@media (min-width: 800px){
	.hero-section:before, .post-type-archive-match:before, .page-template-template-taxonomy_team:before {
		background-image:none;
	}
	.hero-section .banner-100, .post-type-archive-match .banner-100, .page-template-template-taxonomy_team .top-banner-100 {
	    display:unset;
	}
}
@media (min-width: 992px) {
	.custom-flexible-section.hero-section .info-wrapper .primary-wrapper .matches-wrapper .match-wrapper .versus {
	    font-size: 0.9vw;
    }
}
@media (min-width: 1024px) {
	.custom-sponsors .secondary-sponsors .secondary-sponsor {
        padding:10px;
    }
	.archive.category .top-section, .blog .top-section {
		padding-bottom:120px;
	}
}

@media (min-width: 1400px) {
	.custom-flexible-section.hero-section .info-wrapper .secondary-wrapper .image-wrapper img {
        object-fit:cover;
        height:100%;
    }

	.custom-flexible-section.hero-section .info-wrapper .primary-wrapper .matches-wrapper .match-wrapper .versus {
	    font-size: 0.8vw;
    }
}

