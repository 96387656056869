.btn{
    border-radius: 0;
    transition: .25s all ease;
}
.btn-transparent{
    background: transparent;
    padding: 5px 15px;
    &:hover{
        color: $light-grey;
    }
}

.btn-article{
    background: $light-grey;
    border-left: 1px solid $brand-primary;
    border-right: 1px solid $brand-primary;
    border-top: 0 none;
    border-bottom: 0 none;
    font-weight: 800;
    color: $brand-primary;
    margin: 0 15px;
    transform: skewX(-25deg);
    position: relative;
    z-index: 1;
    span{
        display: block;
        transform: skewX(25deg);
    }
    &:hover, &:focus{
        background: $brand-primary;
        color: #fff;
    }
}

.btn-regular{
    background: $light-blue;
    border: 1px solid $light-blue;
    color: #fff;
    text-transform: capitalize;
    &:hover, &:focus{
        color: $light-blue;
        background: #fff;
        transform: scale(1.1);
    }
}
.btn-main{
    background: $red;
    border: 1px solid $red;
    color: #fff;
    text-transform: capitalize;
    &:hover, &:focus{
        color: $red;
        background: #fff;
        transform: scale(1.1);
    }
}
.btn-neutral{
    background: #fff;
    color: $dark-grey !important;
    text-transform: none;
    border: 1px solid $mid-grey;
    &:hover, &:focus{
        color: $dark-grey;
        background: #fff;
        transform: scale(1.1);
    }
}
.btn-table{
    background: inherit;
    border: 0 none;
    border-bottom: 3px solid $light-blue;
    color: $dark-grey !important;
    font-weight: 900;
    text-transform: none;
    padding: 0;
    transform-origin: bottom center;
    &:hover, &:focus{
        color: $dark-grey;
        transform: scale(1.05);
    }
}
.btn-table-main{
    background: inherit;
    border: 0 none;
    border-bottom: 3px solid $red;
    color: $dark-grey !important;
    font-weight: 900;
    text-transform: none;
    padding: 0;
    transform-origin: bottom center;
    &:hover, &:focus{
        color: $dark-grey;
        
        transform: scale(1.05);
    }
}
[class*='btn-table']{
    margin-left: 4px;
    margin-right: 4px;
}

form{
    .btn-regular:hover,
    .btn-regular:focus,
    .btn-main:hover,
    .btn-main:focus{
        transform: none;
    }
}

.further-articles-mobile, .btn-article.blue{
    margin: 15px auto;
    background: $brand-primary;
    border-color: $brand-primary;
    color: #fff;   
    &:hover, &:focus{
        background: $light-blue;
        color: #fff;
    }
}
.btn-article.red{
    margin: 15px auto;
    background: $red;
    border-color: $red;
    color: #fff;   
    &:hover, &:focus{
        background: $light-red;
        color: #fff;
    }
}
.btn-article.light-blue{
    margin: 15px auto;
    background: $light-blue;
    border-color: $light-blue;
    color: #fff;   
    &:hover, &:focus{
        background: lighten($light-blue,5%);
        color: #fff;
    }
}