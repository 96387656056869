
p a {
  color: $link-blue;
}

.lead {
  font-size: inherit;
  font-weight: 800;
}

.entry-content {
  margin-top: 25px;
  margin-bottom: 25px;
}

.img-caption {
  bottom: 0;
  color: #fff;
  padding: 20px;
  float: left;
  left: 10px;
  right: 10px;
  overflow: hidden;
  position: absolute;
  transition: .5s all ease;

  h2 {
    margin-top: 0;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;

    @media(max-width:1300px){
      font-size:18px;
    }

    span {
      font-weight: 300;
    }
  }

  .btn-article {
    float: right;
  }
}


a.cover-link {
  //box-shadow: 0 -270px 70px -150px rgba(0, 0, 0, 0.75) inset;
  box-shadow: 0 -220px 70px -80px rgba(0, 0, 0, 0.75) inset;
  font-weight: normal;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  text-decoration: none;
  transition: .75s all ease;

  & + .img-caption:after {
    bottom: -40px;
    content: "read more...";
    text-transform: uppercase;
    display: block;
    float: right;
    left: auto;
    position: relative;
    right: 0;
  }

  &:hover {
    //     box-shadow: 0 -300px 70px -130px rgba(0, 0, 0, 0.75) inset;
    box-shadow: 0 -240px 70px -60px rgba(0, 0, 0, 0.75) inset;
    & + .img-caption {
      padding-bottom: 55px;
    }
  }
}

.side-bar {
  a.cover-link {
    left: 0;
    right: 0;
  }
}

a.cover-link + .img-caption {
  pointer-events: none;
}


article, .team-panel, article.team-panel {
  .img-caption {
    background: #fff;
    background: rgba(255, 255, 255, 0.75);
    bottom: 0;
    left: 0;
    right: 10%;
    color: $brand-primary;
    position: static;
    width: auto;
    @media (min-width: $screen-sm-min) {
      position: absolute;
    }

    .post-date,
    .post-category {
      font-weight: 800;
    }
  }

}


.article-header {
  color: $brand-primary;
  min-height: 110px;
  position: relative;

  > .img-caption:first-child {
    bottom: -20px;
    padding-bottom: 0;

    h1, h2 {
      line-height: normal;
    }
  }
}


[class*='-panel'] {
  overflow: hidden;

  .post-category {
    padding: 5px 10px;
    background: #fff;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    z-index: 10;
  }
  .post-category--team{
    position: static;
    padding: 0;
    text-transform: uppercase;
    font-size: 16px;
  }

  .row > a {
    background-color: transparent;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    transition: .25s all ease;
  }

  .img-wrapper {
    overflow: hidden;
    position: relative;
    background: #e7e7e7;
    @media (min-width: $screen-lg-min) {
      //min-height: 370px;
    }
  }

  .img-wrapper ~ span.fa.fa-angle-right {
    display: none;
    @media (min-width: $screen-xxs-min) {
      bottom: -60px;
      display: block;
      position: absolute;
      color: $brand-primary;
      right: 3%;
      font-size: 50px;
    }
    @media (min-width: $screen-sm-min) {
      bottom: 20px;
    }
  }

  .img-hover {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: auto;
    opacity: 1;
    top: 0;
    left: 0;
    transition: 1.25s all ease;
  }

  &:hover {
    @media (min-width: $screen-sm-min) {
      .img-caption {
        background: #fff;
        background: rgba(255, 255, 255, .9);
        height: 171px;
      }
      .row > a {
        background: transparent;
        background-color: rgba(0, 0, 0, .1);

      }

      .img-hover {
        /* -webkit-filter: blur(2px);
                  -moz-filter: blur(2px);
                  -o-filter: blur(2px);
                  -ms-filter: blur(2px);
                  filter: url('../images/blur.svg#blur');
                  filter: blur(2px);
                */
        max-width: 110%;
        width: 110%;
        margin-top: -5%;
        margin-left: -5%;
        opacity: 1;
      }

    }
  }

  .img-caption {
    background: #fff;
    background: rgba(255, 255, 255, 0.75);
    bottom: 0;
    left: 0;
    color: $brand-primary;
    height: 151px;
    padding: 20px;
    position: static;
    width: 100%;
    transition: .5s all ease;
    @media (min-width: $screen-sm-min) {
      position: absolute;
      pointer-events: none;
      z-index: 12;
    }

    h1, h2 {
      margin-top: 0;
      font-size: 36px;
      line-height: 1em;
      font-weight: 700;
      min-height: 55px;
      text-transform: none;
      @media (min-width: $screen-sm-min) {
        font-size: 27px;
        line-height: .85em;
      }
      @media (min-width: $screen-md-min) {
        font-size: 36px;
      }
      @media (min-width: $screen-lg-min) {
        font-size: 48px;
      }
      @media (min-width: 1600px) {
        font-size: 56px;
      }

      span.fa-angle-right {
        font-size: 60px;
        float: right;
      }
    }

    h2 {
      span.fa-angle-right {
        font-size: 40px;
      }
    }

    .btn-article {
      float: right;
    }
  }

  .h2 {
    color: $brand-primary;
  }

  .post-date {
    color: $brand-primary;
    font-weight: 800;
  }

  .post-excerpt {
    color: $mid-grey;
  }

  a[class*='-article'] {
    float: right;
  }

  .btn-article {
    @media (max-width: $screen-sm-min - 1) {
      margin-top: 5px;
    }
  }

  &.narrow {
    @media (max-width: $screen-sm-min - 1) {
      .img-caption {
        height: 75px;

        img {
          width: 90%;
          margin: 0 auto;
        }

        h1, h2 {
          background: #fff;
          border: 0 none;
          bottom: 65px;
          padding: 15px;
          position: absolute;
          right: 0;
          width: 90%;
        }

      }
    }


    .img-caption {
      @media (min-width: $screen-sm-min) {
        background: $mid-grey;
        background: rgba(0, 0, 0, .5);
        color: #fff;
      }

      h1, h2 {
        @media (min-width: $screen-sm-min) {
          font-size: 24px;
          font-weight: 300;
          line-height: .95em;
        }
        @media (min-width: $screen-md-min) {
          font-size: 28px;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 30px;
        }
        @media (min-width: 1600px) {
          font-size: 36px;
          font-weight: 700;
        }
      }

      @media (min-width: $screen-sm-min) {
        .post-date {
          color: #fff;
        }
        .btn-article {
          background: $mid-grey;
          background: rgba(255, 255, 255, .33);
          border-color: #fff;
          color: #fff;
        }
      }
    }

    &:hover {
      @media (min-width: $screen-sm-min) {
        .img-caption {
          background: #000;
          background: rgba(0, 0, 0, 0.75);
        }
        .btn-article {
          background: #fff;
          background: rgba(255, 255, 255, .75);
          border-color: #fff;
          color: $brand-primary;
        }
      }


    }
  }

  &.mini {
    margin-top: 20px;
    margin-bottom: 0px;
    min-height: 0;

    h2 {
      font-size: 16px;
      font-weight: 300;
      margin: 0px;
      min-height: 0px;
      @media (min-width: $screen-md-min) {

      }
      @media (min-width: $screen-lg-min) {
        font-size: 18px;
      }
      @media (min-width: 1600px) {
        font-size: 24px;
      }
    }

    .row > a {
      ~ div {
        overflow: hidden;

        > .row {
          overflow: hidden;
        }


        img {
          transition: 1.25s all ease;
        }

      }

      &:hover {
        ~ div img {
          transform: scale(1.15);
        }
      }
    }

    .post-date {
      font-size: 12px;
    }

    .post-excerpt {
      font-size: 14px;
      width: 95%;
    }

    a:hover {
      background: transparent;
    }

    a[class*='-article'] {
      font-size: 21px;
      position: absolute;
      bottom: 0;
      right: 20px;
      transition: .25s all ease;

      &:hover {
        right: 10px;
      }
    }

    &:hover a[class*='-article'] {
      right: 10px;
    }
  }

  .img-caption:after {
    @media (max-width: $screen-sm-min - 1) {
      border-bottom: 1px solid $brand-primary;
      bottom: 2px;
      content: "";
      display: block;
      left: -10px;
      margin-bottom: 20px;
      position: absolute;
      right: -10px;
    }
  }


}

// Panel mods
.hero ~ .news-panel:nth-child(odd) > .row,
.team-container > .team-panel:nth-child(odd) > .row {
  @media (max-width: $screen-sm-min - 1) {
    padding-right: 5px;
  }
}

.hero ~ .news-panel:nth-child(even) > .row,
.team-container > .team-panel:nth-child(even) > .row {
  @media (max-width: $screen-sm-min - 1) {
    padding-left: 5px;
  }
}

.news-panel, .team-panel, .search-results-panel, .page-panel {
  &:hover {
    @media (min-width: $screen-sm-min) {
      a.btn {
        margin-right: 5px;
        margin-top: 10px;
        background: $brand-primary;
        color: #fff;
      }
    }
  }

  &.hero {
    @media (max-width: $screen-sm-min - 1) {
      margin-left: - $grid-gutter-width / 2;
      margin-right: - $grid-gutter-width / 2;
    }
  }
}

.team-panel {
  margin-bottom: 100px;

  .cover-link,
  .cover-link:hover,
  .cover-link:focus {
    box-shadow: none;
  }

  .img-caption {
    height: 100px;

    &:after {
      border: 0 none !important;
    }
  }

  &:hover {
    .img-caption {
      @media (min-width: $screen-sm-min) {
        height: 130px;
      }
    }
  }

  + .row .team-panel {
    .img-caption {
      height: 75px;

      h2 {
        font-size: 28px;
      }
    }

    &:hover {
      .img-caption {
        @media (min-width: $screen-sm-min) {
          height: 100px;
        }
      }
    }
  }


  &.person {
    h1, h2 {
      color: $dark-grey;
      min-height: 18px;//43px;
      font-size: 16px !important;
      margin-bottom: 5px;
      /*
            @media (min-width: $screen-xl-min) {
              min-height: 21px;
            }
      */
    }
  }
}
.shirt-number{
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  @media (min-width:$screen-md-min){
    font-size: 42px;
    margin-left: -10px;
    margin-right: -10px;
    text-align: center;
    letter-spacing: -2px;
  }
}

.news-panel {
  min-height: 350px;
  @media (min-width: $screen-sm-min) {
    min-height: 380px;
  }
  @media (min-width: $screen-md-min) {
    min-height: 360px;
  }
  @media (min-width: $screen-lg-min) {
    min-height: 390px;
  }
  @media (min-width: $screen-xl-min) {
    min-height: 520px;
  }

  h2 {
    margin-bottom: 0px;
    min-height: 55px
  }
  >a{
    display: block;
    position: relative;
    height: 100%;
    z-index: 1;
  }
}

.news-panel.hero {
  min-height: 0px;
}

.news-panel.narrow,
.news--narrow {
  @media (min-width: 600px) and (max-width: 849px) {
    width: 50%;
    float: left;
  }
  .img-caption{
    height: auto;
    padding: 10px 20px;
    h2{
      font-size: 16px;
      line-height: normal;
      margin-bottom: 5px;
      min-height: 0;
    }
    .post-date{
      font-size: 14px;
    }
  }
  [class*=-panel]:hover .img-caption {
    height: auto;
    padding-bottom: 15px;
  }
}


.main-news__top-section{
  @media (min-width:$screen-sm-min){
    display: flex;
  }
  max-width: 100%;
  height: 100%;
}
.flex-row-container--news{
  @media (min-width:850px){
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
  }
  max-width:100%;
  >.flex-item{
    flex: 1;
    display: flex;
    height: 50%;
    position: relative;
    overflow: hidden;
    img{
      object-fit:cover;
    }
    .news-panel{
      min-height: 100%;
      height: 100%;
    }
    a:not(.btn){
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 900;
    }
  }
}
.news-panel--header-ad{
  display: flex;
  //min-height: 100px !important;
  min-width: 100%;
  img{
    max-height: 100%;
    object-fit:cover;
  }
  >a.header-ad__link{
    display: flex !important;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    width: 100%;
  }
  .advert-caption{
    position: absolute;
    bottom:12%;
    right:0;
    background: #fff;
    background: rgba(255, 255, 255, 0.75);
    color: $brand-primary;
    font-size: 12px;
    padding: 10px;
    z-index: 900;
  }
}

.visible-custom-block {
  @media (min-width: 600px) and (max-width: 849px) {
    display: none !important;
  }
}

.row-header{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  h1,h2{
    color: $brand-primary;
    margin-bottom: 0;
    flex:1;
    font-size: 24px;
  }
  a{
    align-self: flex-end;
  }
  @media (min-width:$screen-sm-min){
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
  }

}
.tertiary-news {
  position: relative;

  display: flex !important;
  //    flex-direction: column;

  //@media (min-width:$screen-sm-min){
  flex-direction: row;;
  //}
  @media (max-width: 600px) {
    .news-panel {
      display: none;
    }
    .news-panel:nth-child(1) {
      display: block !important;
    }
  }
  @media (min-width: 600px) {
    display: block;
  }
  @media (min-width: 600px) and (max-width: 849px) {
    width: 50%;
    float: left;
    padding-left: 20px;
  }

  .news-mimi__text{
    background: $light-grey;
    padding: 10px;
    flex: 1;
    height: 100%;
  }
  .news-panel.mini {

    @media (min-width: 600px) and (max-width: 849px) {
      margin-top: 0px;
      margin-bottom: 15px;
    }
    a{
      display: flex;
      width: calc(100% - 20px);
      height: 100%;
      position: absolute;
    }
    img {
      @media (min-width: 600px) and (max-width: 849px) {
        // max-width: 90px;
      }
    }

    h2 {
      color: $brand-primary;
      @media (min-width: 600px) and (max-width: 849px) {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    .post-excerpt {
      @media (min-width: 600px) and (max-width: 849px) {
        display: none;
      }
    }
  }
}

.partners--home,
.tertiary-news{
  >.flex-viewport{
    width: 100%;
    .slides{
      height: 100%;
      >li{
        display: flex !important;
        height: 100%;
      }
    }
  }
  .partner-item.mini{
    // @media (min-width:$screen-xs-min){
    display: flex;
    flex: 1;
    //}
    >a{
      background-color: $light-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      position: relative;
      text-align: center;
      min-width:  100%;
      //    @media (min-width:$screen-xs-min){
      //     display: flex;
      //  }
    }
  }
}
.partners-nav,
.tertiary-news-nav{
  position: relative;
  left: 10px;
  a.further-articles{
    padding-top:50px;
  }
}
.tertiary-news-nav{
  position: absolute;
  right: -10px;
  top: 0;
  left: auto;
  height: 100%;
  a.further-articles{
    padding-top: 0;
  }
}

.main-deetv-inner {
  background: $brand-primary;
  overflow: hidden;
}

.dtv-title {
  padding: 0 15px;
}

.deetv-section {
  /*img, .img-hover{
        max-width: 2000px;
        height: 100%;
        max-height: 100%;
    }*/
  &.hero {
    border-bottom: 5px solid #fff;
    @media (min-width: $screen-sm-min) {
      border-bottom: 0 none;
    }
  }

  &:hover {
    .img-caption {
      background: darken($brand-primary, 10%);
      @media (min-width: $screen-md-min) {
        height: 110px;
      }
      @media (min-width: $screen-xl-min) {
        height: 150px;
      }
    }
  }

  .img-caption {
    background: $brand-primary;
    color: #fff;
    height: 100px;
    padding: 30px;

    h1, h2 {
      font-size: 24px;
      @media (min-width: $screen-sm-min) {
        font-size: 40px;
      }
    }

    @media (min-width: $screen-md-min) {

      width: 90%;
      max-width: 740px;
    }
    @media (min-width: $screen-lg-min) {

      width: 85%;

    }
    @media (min-width: $screen-xl-min) {
      height: 140px;
      padding: 40px;
      h1, h2 {
        font-size: 60px;
      }
      width: 80%;
    }
  }

  + .deetv-section {
    > div > div {
      background: $brand-primary;

      &:nth-child(odd) {
        background: lighten($brand-primary, 5%);
      }
    }

    .details {

      color: #fff;
      position: relative;
      width: 100%;
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      height: auto;

      p, span {
        color: #fff;
        display: block;
      }

      h1, h2 {
        color: #fff;
        font-size: 21px;
        font-weight: 800;
        @media (min-width: $screen-xl-min) {
          margin-top: 6px;
        }
        @media (min-width: 1700px) {
          font-size: 28px;
          margin-top: 25px;
        }
      }

      .btn {
        margin-right: 10px;
      }
    }
  }

  &.hero {
    .img-caption {
      background: rgb(23, 38, 67);
      background: rgba(23, 38, 67, 0.2);
      margin-bottom: 30px;

    }

    &:hover {
      .img-caption {
        background: rgba(23, 38, 67, 1);
      }
    }
  }
}


.addthis-container {
  text-align: right;
}

.person-article {
  float: left;

  .profile-pic {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
      padding-right: $grid-gutter-width / 2;
    }
  }

  .person-info {
    @media (min-width: $screen-xl-min) {
      padding-left: 50px;
      padding-top: 27px;
    }
  }

  h1 {
    margin-top: 0;
    font-size: 36px;
    line-height: 1em;
    font-weight: 700;
    min-height: 55px;
    text-transform: none;
    @media (min-width: $screen-xl-min) {
      font-size: 51px;
      min-height: 65px;
    }
  }

  .article-header {
    strong {
      font-weight: 900;
    }

    p {
      margin-bottom: 10px;
      @media (min-width: $screen-xl-min) {
        font-size: 21px;
      }
    }

    > div + div {
      @media (min-width: $screen-xl-min) {
        padding-right: 40px;
      }
    }

    .img-links {
      padding-left: 0px;
      @media (min-width: $screen-xl-min) {
        padding-left: 40px;
      }

      a {
        display: block;
        margin-top: 20px;
        max-height: 135px;
        overflow: hidden;

        &:nth-child(odd) {
          padding-right: 20px;

          .img-caption {
          }
        }

        &:nth-child(even) {
          padding-left: 20px;

          .img-caption {
            padding-left: 30px;
          }
        }

        &:hover {
          .img-caption {
            bottom: 0;
          }
        }
      }

      .img-caption {
        position: absolute;
        font-size: 12px;
        bottom: 0;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (min-width: $screen-md-min) {
          bottom: -32px;
        }

        h2 {
          font-size: 14px;
        }
      }

      .fa {
        position: absolute;
        left: 46%;
        z-index: 1000;
        top: 45px;
        color: #fff;
        font-size: 40px;
      }
    }
  }

  #twitterBox {
    @media (min-width: $screen-xl-min) {
      padding-right: 30px;
    }
  }

  .post-pos {
    border-bottom: 1px solid $brand-primary;
    border-bottom: 1px solid rgba($brand-primary, .25);
    font-size: 36px;
    font-weight: 900;
    line-height: normal;
    text-align: right;
    @media (min-width: $screen-xl-min) {
      font-size: 44px;
    }

    > span {
      font-size: 300%;
      opacity: 0.5;
    }
  }
}

.biog {
  @media (min-width: $screen-sm-min) {
    margin-left: - $grid-gutter-width / 2;
  }
}

.match-article, .page-article {
  float: left;
  width: 100%;

  h1 {
    margin-top: 0;
    font-size: 36px;
    line-height: 1em;
    font-weight: 700;
    min-height: 55px;
    text-transform: none;
    @media (min-width: $screen-sm-min) {
      font-size: 27px;
      line-height: .85em;
    }
    @media (min-width: $screen-md-min) {
      font-size: 36px;
    }
    @media (min-width: $screen-lg-min) {
      font-size: 48px;
    }
    @media (min-width: 1600px) {
      font-size: 56px;
    }
  }

}

.news-article {
  .article-header {
    background: transparent;
    @media (min-width: $screen-sm-min) {
      background: $dark-blue;
    }

    img {
      margin: 0 auto;
    }
  }
}

.team-container, .search-results {
  @media (min-width: $screen-md-min) {
    margin-bottom: 30px;
  }

  > h2, > div > h2 {
    font-size: 21px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .news-panel, .team-panel, .search-results-panel {
    &.hero {
      margin-bottom: 60px;
      margin-top: 40px;

      @media (min-width: $screen-md-min) {
        img {
          float: left;
          //  max-width: 41.6667%;
        }
        .img-caption {
          float: left;
          padding-left: 30px;
          width: 58.3333%;
          max-width: 500px;
        }
        h1, h2 {
          font-size: 46px;
          line-height: 1em;
        }
        p {
          color: $brand-primary;
          font-size: 18px;
        }
      }

      .post-excerpt {

      }
    }

    @media (min-width: $screen-sm-min) {
      padding-right: 60px;
      margin-bottom: 30px;
    }
    @media (min-width: $screen-xl-min) {
      margin-bottom: 60px;
    }

    > .row {
      position: relative;
    }

    &:hover {
      > .row > a {
        background: transparent;
      }
    }

    .cover-link {
      box-shadow: none;
    }

    .img-caption {
      border-bottom: 1px solid $light-grey;
      position: relative;
      margin-bottom: 30px;
      overflow: visible;
      padding: 10px 10px 30px;
      height: auto;

      &:after {
        display: none;
      }
    }

    .btn-article {
      position: absolute;
      bottom: -12px;
      right: 0;
    }

    h2 {
      font-size: 21px;
      font-weight: 300;
      line-height: 1em;
    }
  }

  .news-panel.hero {
    h2 {
      font-weight: 900;
    }
  }

  .search-results-panel {
    float: left;
    margin-bottom: 30px;
    margin-right: 60px;
    margin-top: 0px;
    min-width: 95%;
    overflow: visible;
    padding-bottom: 30px;
    padding-right: 0;
    position: relative;

    &:after {
      border-bottom: 1px solid $light-grey;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
    }

    .img-caption {
      margin-bottom: 0;
    }

    a.cover-link:hover + .img-caption {
      padding-bottom: 30px;
    }

    .img-wrapper ~ .img-caption {
      padding-left: $grid-gutter-width / 2;
    }

    h1, h2 {
      font-size: 23px;
      line-height: 1em;
      min-height: 0;
    }

    @media (min-width: $screen-sm-min) {
      img {
        float: left;
        //  max-width: 41.6667%;
      }
      .img-wrapper ~ .img-caption {
        float: left;
        padding-left: 30px;
        width: 75%;
        max-width: 650px;
      }

      h1, h2 {
        font-size: 26px;
      }
    }

    .img-caption {
      border-bottom: 0 none;

      .post-category {
        background: transparent;
        padding-left: 0;
        position: static;

        &:before {
          content: ' / ';
        }
      }
    }

    p {
      color: $brand-primary;
      font-size: 18px;
    }

    //}
    .post-excerpt {

    }

    &:hover {
      .btn-article {
        margin-right: 9px;
      }
    }

    .btn-article {
      margin-right: 20px;
    }

  }
}

.team-container {
  .team-panel {
    a.btn-article {
      display: none;
    }

    a.cover-link ~ .img-caption a.btn-article {
      display: block;
    }
  }
}


.top-margin {
  margin-top: 40px;
}

.further-articles-container{
  @media (min-width: 600px) and (max-width: 849px) {
    width:50%;
    margin-left:50%;
  }
}
.further-articles {
  bottom: 0;
  display: none;
  float: right;
  font-size: 35px;
  height: 150px;
  line-height: 2em;
  position: absolute;
  right: -25px;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  z-index: 999;
  text-decoration: none !important;
  transition: .25s all ease;
  // @media (min-width: $screen-sm-min) {
  display: flex;//block;
  align-items: center;
  //}
  @media (min-width: $screen-lg-min) {
    right: -50px;
  }

  &:hover {
    padding-left: 5px;
    padding-right: 0;
  }
}


body.league-table {
  //   h1 {
  //       margin-top:40px;
  //  }
  padding-left: 10px;
  padding-right: 10px;

  > div.table-responsive {
    margin-right: -10px;
  }

  table {

    thead {
      th {
        text-align: center;
      }
    }

    tbody {
      tr {
        &:first-child {
          background: darken($light-grey, 10%);
        }

        background: darken($light-grey, 3%);
        transition: .25s all ease;

        &:nth-child(even) {
          background: $light-grey !important;
        }

        td {
          background: transparent;
          color: $brand-primary;
          display: table-cell !important;
          float: none !important;
          padding: 10px;
          text-align: left !important;
          transition: .25s all ease;
          width: auto !important;
          font-weight: normal;

          img {
            @media (max-width: $screen-sm-min) {
              max-width: 25px !important;
            }
          }
        }

        &.active {
          background: $light-blue !important;

          td {
            background: $light-blue !important;
            color: #fff;
          }
        }

        /* &:hover{
                     background: lighten($light-blue, 10%) !important;
                     td{
                         background: lighten($light-blue, 10%) !important;
                         color: #fff;
                     }
                 } */
      }
    }
  }

  .table-striped > tbody > tr:nth-of-type(2n+1) {
    background: $light-grey;
  }


}

body.home {
  .league-table {
    padding-left: 10px;
    padding-right: 10px;

    > div {
      margin-right: -10px;
    }

    table {

      thead {
        th {
          text-align: center;
        }
      }

      tbody {
        tr {
          background: darken($light-grey, 10%);
          transition: .25s all ease;

          td {
            color: $brand-primary;
            padding: 5px 10px;
            @media (min-width: $screen-md-min - 1) and (max-width: $screen-xl-min) {
              font-size: 15px;
            }
            @media (min-width: $screen-lg-min) {
              padding: 10px;
            }
            transition: .25s all ease;
          }

          &.active {
            background: $light-blue !important;

            td {
              background: $light-blue !important;
              color: #fff;
            }
          }

          /*&:hover{
                        background: lighten($light-blue, 10%) !important;
                        td{
                            background: lighten($light-blue, 10%) !important;
                            color: #fff;
                        }
                    } */
        }
      }
    }

    .table-striped > tbody > tr:nth-of-type(2n+1) {
      background: $light-grey;
    }


  }
}

.ads {
  > div {
    margin-bottom: 20px;
    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
section.ads{
  display: flex;
  flex-wrap: wrap;
  .ad-wrapper{
    height: 0;
    padding-bottom:72%;
    width:100%;
    img, video{
      height:100%;
      width:100%;
      position:absolute;
      top:0;
      left:0;
      object-fit: cover;
      min-height: 100%;
    }
  }
}

.ad-wrapper, .tv-inner {
  position: relative;
}

.ads, .ad-wrapper, .tv-inner {
  .img-caption {
    @media (max-width: $screen-sm-min - 1) {
      float: none;
      left: auto;
      right: auto;
      padding: 20px 0 !important;
      width:100%;
      h2 {
        padding-left: 20px;
        padding-right: 20px;
      }
      > span {
        bottom: 0;
        float: left;
        font-size: 20px;
        width: 100%;
        color: #fff;
        background: $newGold;
        padding: 15px;
        font-weight:800;
      }
      &:after {
        display: none;
        content:none !important;
      }
    }
  }

}

.dfps {
  @media (min-width: $screen-md-min) {
    display: table;
    height: 100%;
  }

  > div {
    @media (min-width: $screen-md-min) {
      height: 100%;
      position: relative;
    }
    @-moz-document url-prefix() {
      display: table;
    }
  }
}

.dfp-wrapper {
  background: $light-grey;
  min-height: 280px;
  position: relative;
  @media (min-width: $screen-md-min) {
    display: table;
    min-height: 250px;
  }
  width: 100%;
  height: 100%;
}

.side-bar .dfp-wrapper {
  @media (min-width: $screen-md-min) {
    min-height: 250px;
  }
}

.dfp-container {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /*transform: translateX(-50%);
    transform: translateY(-50%);*/

  height: 250px;
  width: 300px;
}

.dfps .ad-wrapper, .tv-inner {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height:0 !important;
  @media (min-width: $screen-md-min) {
    margin: 0;
  }

  height:0;
  padding-bottom:70%;
  width:100%;

  img, video{
    position:absolute;
    height:100%;
    width:100%;
    object-fit: cover;
    left:0;
    top:0;
  }
}

.dfps img, .ads img, .ad-wrapper img {
  margin: 0 auto;
}

.video-box {


  [class*='play'] {
    color: #fff;
    display: block;
    font-size: 60px;
    margin-top: -30px;
    position: relative;
    top: 50%;
    font-weight: 100;
    transition: .5s all ease;
  }

  &:hover {
    [class*='play'] {
      margin-top: -60px;
    }
  }
}

.side-bar {
  .dfp-wrapper, .ad-wrapper {
    margin-bottom: 50px;
  }

  .ads a {
    display: block;
  }
}

/*
 *
 * MATCHES
 *
 ****************/

.matches-row {
  @media (min-width: $screen-md-min) {
    height: 390px;
  }
  @media (min-width: $screen-lg-min) {
    height: 440px;
  }

  .ad {
    margin-top: 20px;
    @media (min-width: $screen-md-min) {
      margin-top: 0;
      height: 100%;
    }
  }

  .img-caption,
  .cover-link {
    left: 0;
    right: 0;
  }

  .ad-wrapper {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      display: block;
      max-height: 100%;
      width: auto;
      object-fit:cover;
      @media (min-width: $screen-md-min) {
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        display: block;
        height: auto;
        min-height: 100%;
        max-width: 500%;
      }
      @media (min-width: $screen-lg-min) {
        display: block;
        height: auto;
        max-width: 100%;

        max-height: 1024px;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        /* transform: translateX(-50%);
                 transform: translateY(-50%);*/
      }
    }
  }
}

.matches-panel {

  .matches-wrapper {
    background: $brand-primary;
    padding-top: 15px;
    @media (max-width: $screen-sm-min - 1) {
      margin-left: - $grid-gutter-width / 2;
      margin-right: - $grid-gutter-width / 2;
    }
    @media (min-width: $screen-sm-min) {
      display: table;
      width: 100%;
      height: 100%;

    }

    > div {
      float: left;
      width: 100%;
      @media (min-width: $screen-sm-min) {
        width: 50%;

      }
    }
  }

  a {
    color: #fff;
  }

  .match-nav {
    @media (max-width: $screen-sm-min - 1) {
      position: absolute;
      z-index: 100;
      right: 0;
    }

    a {
      padding: 5px;
      position: relative;
      transition: .25s all ease;

      &:hover, &:focus {
        color: $bright-blue;
      }

      @media (max-width: $screen-sm-min - 1) {
        //  margin-left: 5px;
        &:first-child i {
          border-right: 1px solid #fff;
          padding-right: 11px;
        }
      }
    }

    .fa {
      font-size: 34px;
      @media (min-width: $screen-sm-min) {
        font-size: 24px;
      }
    }

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
  }

  @media (min-width: $screen-sm-min) {

    height: 100%;
    @-moz-document url-prefix() {
      display: table;
    }
  }

  .next-match, .league-table {
    background: $brand-primary;
    color: #fff;
    padding-bottom: 19px;
    @media (min-width: $screen-sm-min) {

      height: 100%;
      width: 100%;
    }
  }

  .last-match,
  .next-match {
    padding-right: 10px;
    padding-left: 10px;
    @media (min-width: $screen-md-min) and (max-width: 1024px) {
      font-size: 14px;
    }
    @media (min-width: $screen-xl-min) {
      .item {
        > div > .row + .row {
          padding-left: 30px;
          padding-right: 30px;
        }

        > div:nth-child(2) {
          padding-left: 40px;
        }

        > div:last-child {
          padding-right: 40px;
        }
      }
    }

    .btn {
      margin-bottom: 10px;
      padding: 6px;
      width: 100%;
      @media (min-width: $screen-md-min) and (max-width: 1024px) {
        font-size: 13px;
      }
    }

    .match-header {
      margin-top: 7px;
    }
  }

  .team-badge, img {
    margin: 7px auto 0;
    max-height: 139px;
  }


}

.match .vs, .vs {
  font-size: 26px;
  font-weight: 800;
  padding: 14% 0 0 0;
  text-align: center;
}

.match-slider {
  .slide-header {
    p {
      margin: 0px;

      &:first-child {
        margin-top: 20px;
      }
    }

    strong {
      font-size: 20px;
    }
  }

  .slide-mid {
    margin: 25px 0;
  }

  .crest {
    img {
      max-height: 115px;
    }
  }
}

.item {
  > .row {
    > div {
      text-align: center;
    }
  }

  .team-badge {
    display: inline-block;
    max-height: 115px;
    transition: all 0.3s ease;
  }

  .team-name {
    padding: 10px 0;
    margin-bottom: 0px;
  }

  .scorers {
    background: darken($brand-primary, 10%);
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    margin: 0 -30px 20px -20px;
    padding: 5px 10px;

    span {
      font-weight: 600;
      float: right;
      padding-left: 5px;
    }

    span.highlight {
      color: #a9effd;
    }

  }

  .away .scorers {
    text-align: left;
    margin: 0 -20px 20px -30px;

    span {
      float: left;
      padding-right: 5px;
    }

    span.highlight {
      color: #8cace8;
    }
  }
}


.featured-matches {
  overflow: hidden;
}

.last-match,
.upcoming-matches {
  background: $brand-primary;
  color: #fff;
  padding: 15px 0 10px 0;
  @media (max-width: $screen-sm-min - 1) {
    float: left;
    width: 100%;
  }

  .match-nav {
    float: left;
  }

  .slides {
    margin-bottom: 15px;

    > li {
      border-right: 1px solid #fff;
      border-right: 1px solid rgba(255, 255, 255, .25);
    }
  }

  .item {
    font-weight: 300;
  }

  .match-footer {
    border-top: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    margin: 10px auto 0;
    padding: 10px 0;
    width: 90%;

    > div {
      @media (min-width: $screen-md-min) {
        width: 50%;
        float: left;
      }
    }
  }

  .flex-viewport:after {
    background: $brand-primary;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    width: 5px;
    z-index: 10;
  }
}

.last-match {
  background: $light-grey;
  color: $dark-grey;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;


  .match-heading {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    text-align:center;
    color:$newLightBlue;
    font-weight:700;
  }

  @media (min-width: $screen-sm-min) {
    display: table;
    height: 100%;
  }

  .item {
    float: left;
    margin-bottom: 15px;
    overflow: hidden;
    width: 100%;
  }

  .match-footer {
    border-top: 1px solid $mid-grey;
  }
}

.live-match {
  margin-top: 80px;
  background: transparent;

  p {
    font-size: 28px;
  }

  .vs {
    padding-top: 7%;
    font-size: 36px;
    @media (min-width: $screen-lg-min) {
      font-size: 46px;
    }
    @media (min-width: $screen-xl-min) {
      padding-top: 5%;
      font-size: 60px;
    }
  }

  .match-footer {
    border-top: 1px solid $mid-grey;
    margin-top: 30px;
    padding-top: 30px;

    p {
      font-size: 17px;
    }
  }
}

.live-commentary-container {
  .live-text p {
    margin: 0px;
  }
}

.live-text {
  margin-bottom: 30px;

  // margin-left: - $grid-gutter-width / 2;
  // margin-right: - $grid-gutter-width / 2;
  > div > div {
    background: $light-grey;
    float: left;
    width: 100%;
    padding: 20px 10px;

    p {
      padding: 0;
    }

    .time {
      font-weight: 800;
      @media (min-width: $screen-sm-min) {
        text-align: center;
      }
    }
  }

  > div > div:nth-child(even) {
    background: lighten($light-grey, 5%);
  }
}

.match-heading {
  overflow: hidden;

  img {
    float: right;
  }
}

// Match Stats

.data-visualisation,
.side-bar .data-visualisation {
  padding: 0px;
  @media (min-width: $screen-sm-min) {
    padding: 0 20px;
  }

  h3 {
    padding: 0 20px;
    padding-top: 30px;
    @media (min-width: $screen-sm-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  li {
    display: block;
    overflow: hidden;
    margin-bottom: 30px;

    .left, .right {
      position: relative;
      width: 50%;
    }

    .left {
      float: left;
      text-align: left;
      padding-right: 10px
    }

    .right {
      float: right;
      text-align: right;
      padding-left: 10px;
    }

    padding: 0 20px;
    @media (min-width: $screen-sm-min) {
      padding: 0px;
    }

    &:first-child {
      padding: 0px;
    }

    .possession {
      p {
        padding-left: 20px;
        @media (min-width: $screen-sm-min) {
          padding-left: 0px;
        }
      }
    }
  }

  .bars {
    > p {
      margin-bottom: 0;
    }

    div {
      p {
        font-weight: 700;
        font-size: 39px;

        span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .bar {
    display: block;
    background: $brand-primary;
    padding: 0;

    > span {
      background: $bright-blue;
      display: block;
      height: 100%;
      transition: 1s all ease;
    }
  }

  .right .bar > span {
    float: right;
    min-height: 5px;
  }

  .shots.bars,
  .fouls.bars {
    .bar {
      background: $link-blue;
    }
  }

  .bars .right .bar > span {
    background: $link-blue;
  }
}

// SVG Circles

.stroke-width-5 {
  svg {
    stroke-width: 5px;
  }

  .bar-h {
    height: 5px;
  }

  .bar-v {
    width: 5px;
  }

  .bar, .bar > span {
    min-height: 5px;
  }
}

.possession.circles {
  .circle-holder {
    display: block;
    width: 150px;
    margin: 0 auto;
  }

  .pcnt {
    font-size: 28px;
    position: absolute;
    text-align: center;
    top: 48%;
    transform: translateY(-50%);
    width: 150px;
  }

  .poss-circle-opp {
    stroke: $brand-primary;
  }

  .poss-circle-dfc {
    stroke: $bright-blue;
  }

  .right {
    .poss-circle-dfc {
      stroke: $link-blue;
    }
  }
}

// Cards

.cards {
  [class*="-card"] {
    margin-bottom: 10px;
    padding-left: 21px;
    font-size: 14px;
    font-weight: 700;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 13px;
      height: 18px;
      left: 0;
      position: absolute;
    }
  }

  .yellow-card:before {
    background: yellow;
  }

  .red-card:before {
    background: red;
  }

  .right {
    [class*="-card"] {
      padding-left: 0;
      padding-right: 21px;

      &:before {
        right: 0;
        left: auto;
      }
    }
  }
}


// Match tables


table.fixture-table {
  color: $dark-grey;
}

.matches-tables .month {
  h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 15px;
    color:white;
    @media (min-width: $screen-sm-min) {
      padding-left: 25px;
    }
    @media (min-width: $screen-lg-min) {
      padding-left: 50px;
    }
  }
}

table.fixture-table > thead > tr > th,
table.fixture-table > tbody > tr > th,
table.fixture-table > tfoot > tr > th,
table.fixture-table > thead > tr > td,
table.fixture-table > tbody > tr > td,
table.fixture-table > tfoot > tr > td {
  vertical-align: middle;
  font-size: 15px;
  @media (min-width: $screen-xl-min) {
    font-size: 17px;
  }
}

table.fixture-table > thead > tr > th img,
table.fixture-table > tbody > tr > th img,
table.fixture-table > tfoot > tr > th img,
table.fixture-table > thead > tr > td img,
table.fixture-table > tbody > tr > td img,
table.fixture-table > tfoot > tr > td img {
  max-width: 50px;
  height: auto;
}

table.fixture-table td {
  background: $light-grey;
  font-weight: 300;
}

table.fixture-table td.score {
  background-color: lighten($mid-grey, 10%);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  min-width: 60px;
}

table.fixture-table tr:first-child {
  //border-top:2px solid $mid-grey;
}

table.fixture-table a {
  color: #022169;
}

table.fixture-table a:hover {
  color: #cf0a2c;
  text-decoration: none;
}


.table.fixture-table tr > td:last-child {
  text-align: left;
}

.matches-tables {
  .table.fixture-table tr {
    border-bottom: 5px solid #fff;
    @media (min-width: $screen-md-min) {
      border-bottom: none;
    }
  }
}

.matches-tables.full-league {
  .table.fixture-table tr {
    border-bottom: none;
  }
}

@media (min-width: $screen-md-min) {
  .table.fixture-table tr {
    > td:nth-child(1) {
      width: 180px;
    }

    > td:nth-child(2) {
      width: 20%;
    }

    > td:nth-child(8) {
      width: 17%;
    }

    > td:nth-child(3) {
      text-align: right;
      width: 10%;
    }

    > td:nth-child(7) {
      width: 10%;
    }

    > td:nth-child(4), td:nth-child(6) {
      width: 70px;
    }

    > td.score {
      width: 90px;
    }
  }
}

@media (max-width: $screen-lg-min - 1) {
  .table.fixture-table tr > td img {
    max-width: 40px;
  }
  .table.fixture-table tr > td:last-child {
    text-align: center;
  }
  table.fixture-table td.score {
    font-size: 16px;
  }
}

@media (max-width: $screen-md-min - 1) {

  .table.fixture-table tr {
    //	border-bottom:15px solid #fff;
  }
  .table.fixture-table tr > td {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    float: left;
    line-height: 2em;
    min-height: 54px;
    width: 50%;
  }
  .table.fixture-table tr > td img {
    //max-height:30px;
    margin-top: 3px;
  }
  .table.fixture-table tr > td:nth-child(1) {
    text-align: right;
    width: 50%;
  }
  .table.fixture-table tr > td:nth-child(2) {
    text-align: left;
    width: 50%;
  }
  .table.fixture-table tr > td:nth-child(3) {
    text-align: right;
  }
  .table.fixture-table tr > td:nth-child(3), .table.fixture-table tr > td:nth-child(4), .table.fixture-table tr > td:nth-child(5), .table.fixture-table tr > td:nth-child(6), .table.fixture-table tr > td:nth-child(7) {
    font-size: 14px;
    width: 32%;
  }
  .table.fixture-table tr > td:nth-child(4), .table.fixture-table tr > td:nth-child(6) {
    text-align: center;
    width: 12%;
    overflow: hidden;
    padding: 0;
  }

  .table.fixture-table tr > td:nth-child(4) > img, .table.fixture-table tr > td:nth-child(6) > img {
    //max-height:30px;
  }

  .table.fixture-table tr > td:nth-child(5) {
    width: 12%;
    min-width: 20px;
  }

  .table.ministandings.fixture-table tr > td {
    float: none;
    display: table-cell;
  }

  .table.ministandings.fixture-table tr > td:nth-child(1) {
    text-align: center;
  }

  .table.ministandings.fixture-table tr > td:nth-child(2) {
    text-align: left;
  }


}

@media (max-width: $screen-xxs-min - 1) {
  .table.fixture-table tr > td:nth-child(1),
  .table.fixture-table tr > td:nth-child(2),
  .table.fixture-table tr > td:nth-last-child(2),
  .table.fixture-table tr > td:last-child {
    text-align: center;
    width: 100%;
  }
  .table.fixture-table tr > td img {
    max-width: 88%;
    margin-top: 4px;
  }
  .table.fixture-table tr > td:nth-child(3), .table.fixture-table tr > td:nth-child(4), .table.fixture-table tr > td:nth-child(5), .table.fixture-table tr > td:nth-child(6), .table.fixture-table tr > td:nth-child(7) {
    font-size: 13px;
    line-height: 2.5em;
  }
}

// Page Panels


.page-panel {
  margin-bottom: 0px;

  .cover-link,
  .cover-link:hover,
  .cover-link:focus {
    box-shadow: none;
  }

  .img-caption {
    height: 80px;
    width: 100%;

    h2 {
      font-size: 24px;
    }

    &:after {
      border: 0 none !important;
    }

    @media (min-width: $screen-xs-min) {
      width: 80%;
    }
  }

  &:hover {
    .img-caption {
      @media (min-width: $screen-sm-min) {
        height: 100px;
      }
    }
  }
}

.side-nav {
  li.page_item {
    border-top: 1px solid $dark-blue;
    padding: 45px 30px 40px 50px;

    a {
      font-size: 24px;
    }
  }

  .children {
    li.page_item {
      border-top: none;
      padding: 0px;

      a {
        font-size: 18px;
      }
    }
  }
}

// Search results
.search-results {
  // @extend .margin-top;

}

.alert-punctuation {
  background: #fff;
  border: none;
  color: $brand-primary;

  span {
    font-size: 40px;
  }
}

.page-form {
  .search-form {
    label {
      width: 100%;
      border: 1px solid $brand-primary;
      @media (min-width: $screen-sm-min) {
        width: 40%;
      }
    }

    .search-submit {
      width: 100%;
      background: $light-blue;
      color: #fff;
      border: 1px solid $light-blue;
      min-height: 36px;
      @media (min-width: $screen-sm-min) {
        width: 20%;
      }

      &:hover {
        background: #fff;
        color: $light-blue;
      }
    }
  }
}

.pagination {
  margin: 15px 0;
  display: inline-block;
  width: 100%;
  min-height: 100px;
  text-align: center;

  .page-numbers {
    display: none;
    color: $newGold;
    padding: 8px;
    vertical-align: middle;
    min-height: 20px;
    padding-bottom: 10px;
    transition: all 0.3s ease;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }

    &:hover {
      padding-bottom: 5px;
      vertical-align: middle;
    }
  }

  .page-numbers.dots {
    &:hover {
      padding-bottom: 10px;
    }
  }

  .page-numbers.current {
    color: black;
    background: $newGold;

    &:hover {
      padding-bottom: 10px;
    }
  }

  a.prev, a.next {
    padding: 10px 20px;
    min-width: 110px;
    margin: 0px 15px;
    display: inline-block;
    background: $newGold;
    border: 1px solid $newGold;
    color: black;
    text-transform: capitalize;
    transition: 0.3s all ease;
    @media (min-width: $screen-sm-min) {
      min-width: 130px;
    }

    &:hover, &:focus {
      text-decoration: none;
      color: $light-blue;
      background: #fff;
      transform: scale(1.1);
      padding-bottom: 10px;
    }
  }


  /* a{
         min-height: 33px;
     }
     .btn{
         margin: 0 20px;
     }
     ul{
         display: inline-block;
         list-style: none;
         padding-left: 0;
         li{
             display: inline-block;
             a{
                 border: 1px solid $link-blue;
                 padding: 2px 10px 0;
                 display: block;

                 transition: .25s all ease;
                 &.active,
                 &:hover,
                 &:focus{
                     background: $light-blue;
                     color: #fff;
                     text-decoration: none;
                 }
             }
         }
     } */


}

// Modals

.modal-person {
  @media (min-width: $screen-xl-min) {
    width: 1200px;
  }

  .close {
    color: #fff;
    text-shadow: none;
    opacity: 0.9;
    font-size: 17px;
    vertical-align: middle;
    padding: 10px 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease;
  }

  .close:hover {
    background: rgba(0, 0, 0, 1);
    opacity: 1;
  }
}

// DeeTV

h1, h2, h3 {
  a.small {
    color: $link-blue;
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    overflow: hidden;
    text-decoration: none;
    text-indent: -5px;
    transition: .25s all ease;

    &:hover, &:focus {
      text-indent: 2px;
    }
  }
}

.dtv-title {
  h1 {
    padding: 20px 10px;
    font-weight: bold;
    font-size: 40px;
    color: $brand-primary;
  }
}

.main-deetv-wrapper {
  background: $brand-primary;
  overflow: hidden;

  a {
    color: #fff;
  }
}

.video-categories {
  h2 {
    font-size: 21px;
    @media (min-width: $screen-xl-min) {
      font-size: 24px;
    }

    a {
      display: block;
      margin-top: 5px;
      padding: 10px 0;

      @media (min-width: $screen-xl-min) {
        display: inline-block;
        margin: 0;
        margin-left: 5px;
        padding: 0;
      }
    }
  }
}

.tv-wrapper {

  /*@media (min-width:$screen-xs-min) and (max-width:$screen-md-min - 1){
        width: 50%;
        float: left;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        &:nth-child(even){
           padding-left: 0;
        }
        &:nth-child(odd){
           padding-right: 0;
        }
    } */
  .cover-link {
    z-index: 100;
    box-shadow: none;

    &:hover, &:focus {
      box-shadow: none;

      ~ .img-hover {
        max-width: 110%;
        width: 110%;
        margin-top: -5%;
        margin-left: -5%;
        opacity: 1;
      }

      ~ .img-caption {
        padding-bottom: 30px !important;
      }
    }

  }

  .img-caption {
    background: $brand-primary;
    color: #fff;
    left: 0;
    right: auto;
    padding: 20px 25px !important;
    position: absolute;

    p {
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 0;
      @media (min-width: $screen-xl-min) {
        font-size: 22px;
      }

      span {
        font-size: 18px;
        @media (min-width: $screen-xl-min) {
          font-size: 22px;
        }
      }
    }
  }
}

.tv-inner {
  overflow: hidden;
  position: relative;

  .img-hover {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: auto;
    opacity: 1;
    top: 0;
    left: 0;
    transition: 1.25s all ease;
  }
}

.tv-details {
  border-bottom: 1px solid $light-grey;
  font-size: 14px;
  padding: 10px;

  @media (min-width: $screen-xs-min) {
    min-height: 135px;
    overflow: hidden;
  }
  @media (min-width: $screen-md-min) {
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media (min-width: $screen-xl-min) {
    font-size: 18px;
  }

  h3 {
    font-size: 14px;
    margin: 0 0 10px;
    line-height: 1.4;
    @media (min-width: $screen-md-min) {
      font-size: 16px;
      height: 105px;
      margin-bottom: 20px;
    }
    @media (min-width: $screen-xl-min) {
      font-size: 18px;
    }
  }

  span {
    color: $mid-grey;
  }

  span a {
    color: $mid-grey;
    text-decoration: none;
    transition: .25s all ease;

    &:hover {
      color: $link-blue;
    }
  }

  p {

  }

  .post-date {
    float: right;
  }

  .post-title {
    @media (min-width: $screen-xs-min) {
      height: 70px;
      overflow: hidden;
    }
    @media (min-width: $screen-md-min) {
      height: 50px;
    }
  }

}

.main-deetv-wrapper {
  .details {
    .btn-article {
      margin-bottom: 10px;
      margin-top: 9px;
    }
  }

  .details.login {
    background: $light-blue;
    @media (min-width: $screen-xl-min) {
      padding-bottom: 20px;
      padding-top: 15px;
    }
    @media (max-width: $screen-xl-min - 1) {
      text-align: center;
      h2 {
        display: inline;
      }
    }

    h2 {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 15px;
    }

    .btn-article {
      @media (max-width: $screen-xl-min - 1) {
        float: none;
        margin-left: 15px;
      }
      @media (min-width: $screen-xl-min) {
        margin-top: 2px;
        margin-bottom: 2px;
        transform: skewX(-25deg) scale(1.15);
      }
    }
  }
}

.col-sm-4 {
  .tv-wrapper {
    .tv-inner {
      max-height: auto;
      @media (min-width: $screen-sm-min) {
        max-height: 137px;
      }
      @media (min-width: $screen-md-min) {
        max-height: 166px;
      }
      @media (min-width: $screen-lg-min) {
        max-height: 160px;
      }
      @media (min-width: 1375px) {
        max-height: 200px;
      }
      @media (min-width: $screen-xl-min) {
        max-height: 225px;
      }
      @media (min-width: 1700px) {
        max-height: 260px;
      }
      @media (min-width: 1860px) {
        max-height: 291px;
      }
    }
  }
}

.col-md-3 {
  .tv-wrapper {
    .tv-inner {
      max-height: auto;
      @media (min-width: $screen-sm-min) {
        max-height: 210px;
      }
      @media (min-width: $screen-md-min) {
        max-height: 123px;
      }
      @media (min-width: $screen-lg-min) {
        max-height: 125px;
      }
      @media (min-width: $screen-xl-min) {
        max-height: 165px;
      }
      @media (min-width: 1700px) {
        max-height: 191px;
      }
      @media (min-width: 1860px) {
        max-height: 215px;
      }
    }
  }
}

// Live video player

#se_video_wrapper {
  /*margin: 30px auto;*/
  + a {
    background: transparent !important;
    display: inline-block;
    position: static;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    font-size: 14px;

  }
}

.deetv-section + .video-details {
  .details {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: $screen-md-min) {
      min-height: 235px;
    }
    @media (min-width: $screen-lg-min) {
      min-height: 240px;
    }

    h1 {
      font-size: 36px;
    }

    h1, h2 {
      span {
        color: $red;
        display: inline;
      }
    }
  }
}

ul.faqs {
  list-style: none;
  padding-left: 0;

  > li {
    margin-bottom: 25px;

    .q {
      color: $red;
      font-weight: 700;
    }
  }
}

// MixitUp

.js #mixContainer .mix {
  display: none;
}

.novid p {
  color: white;
}

.novid {
  padding: 10%;
}



// Teams archive template

.page-template-template-taxonomy_team{

  .row.team-row{
    display:flex;
    justify-content: center;
    @media(max-width:850px){
      flex-direction: column;
    }
  }


  main.main{
    @media (min-width:$screen-sm-min){
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
    }
    .team-article{
      position:relative;
      overflow: visible !important;

      .overlay-background{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        object-fit: cover;
        z-index:1;
      }

      .cover-link{
        z-index:10000;
      }

      &.featured-team-panel{

        .inner-wrapper{
          width:80%;
          margin:0 auto;

          .overlay-img{
            position:absolute;
            bottom:0;
            z-index:10;
            left:50%;
            transform:translate(-50%, 25%);
            width:22%;
          }

          @media(max-width:992px){
            width:100%;
          }

          h2{
            font-size:48px;
          }

        }
      }


      .article-header{
        height: 100%;
      }
      .img-wrapper{
        height: 100%;
        position:relative;

        &::before{
          content: "";
          height: 50%;
          width: 100%;
          position: absolute;
          background-image: linear-gradient(to bottom,rgba(255,0,0,0),#233a5a);
          bottom: 0;
          left: 0;
          z-index: 2;
        }

        img{
          object-fit:cover;
          min-height: 100%;
        }
      }

      .angle-wrapper{
        position:absolute;
        right:5%;
        bottom:0;
        transform:translateY(50%);
        z-index:100;

        .angle-link{
          height:100%;
          width:100%;
          display:block;
        }

        &:before{
          pointer-events: none;
          content:"";
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.12));
          height:70px;
          width:70px;
          background:white;
          display:flex;
          align-items: center;
          justify-content: center;
          border-radius:50%;
        }

        .fa-angle-right{
          color:$newGold;
          position:absolute;
          top:50%;
          left:50%;
          font-size:30px;
          transform:translate(-50%,-50%);
        }

        svg{
          top:50%;
          left:50%;
          transform:translate(-50%,-50%);
          height:140%;
          width:140%;
          position:absolute;
          z-index: -1;
        }
      }

      .overlay{
        position:absolute;
        bottom:0;
        padding:50px 25px;
        z-index:10;
        h2{
          text-transform:uppercase;
          font-weight:800;
          color:white;
          margin:0;
        }
      }
    }
  }
}

.custom-parent-page{
  margin-top:50px;
  .page-panel{
    margin-bottom:100px;
    position:relative;
    overflow: visible !important;

    .cta{
      position:absolute;
      background:$newGold;
      color:black;
      text-transform:uppercase;
      width:fit-content;
      padding:10px 20px;
      bottom:0;
      left:50%;
      transform:translate(-50%, 50%);
      z-index:10;
      font-size:18px;
      font-weight:800;
      max-width: 80%;
      text-align: center;

      @media(max-width:576px){
        font-size:14px;
      }

    }

    .image-wrapper{
      position:relative;
      overflow: hidden;
      height:100%;
      min-height:510px;
      background:$newDarkBlue;

      &::before{
        content: "";
        height: 50%;
        width: 100%;
        position: absolute;
        background-image: linear-gradient(to bottom,rgba(255,0,0,0),#233a5a);
        bottom: 0;
        left: 0;
        z-index: 2;

        @media(max-width:576px){
          height:80%;
        }
      }

      .overlay{
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        object-fit: cover;
      }


      .image-caption{
        z-index:10;
        position:absolute;
        bottom:50px;
        text-transform:uppercase;
        left:25px;
        color:white;
        h2{
          font-size:40px;

          @media(max-width:768px){
            font-size:30px;
          }

          font-weight:800;
          margin:0;
        }
      }
    }
  }
}

.custom-dropdown{
  position:absolute;
  z-index:1000;
  width:100%;
  display:flex;
  justify-content: flex-end;
  top:-89px;

  &.has-child-page{
    top:-125px;
    @media(max-width:992px){
      top:0;
    }
  }

  @media(max-width:992px){
    justify-content: flex-start;
    position:relative;
    top:0;
    margin-bottom:25px;
    .custom-wrapper{
      width:100%;
    }
  }


  .dropdown-toggle{
    font-size:30px;
    background:$newLightBlue;
    color:white;
    padding: 10px 30px;
    width:420px;
    @media(max-width:992px){
      width:100%;
    }
    @media(max-width:576px){
      font-size:22px;
    }
    text-align:left;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .fa-chevron-down{
      color:$newMainBlue;
    }
  }
  .dropdown-menu{
    background:$newLightBlue;
    font-size:24px;
    padding:15px;
    width:100%;
    border-radius:0;
    @media(max-width:576px){
      font-size:18px;
    }
    a{
      color:white;
    }
  }
  ul{
    padding-left:0;
    margin-bottom:0;
    li{
      list-style-type: none;
    }
  }
}

.custom-single-page{
  .banner{
    height:500px;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    background-attachment: fixed;
    overflow: hidden;
  }

  h1{
    text-transform:uppercase;
    color:white;
    padding-top:100px;
  }

  .page-article{
    .article-header{
      img{
        height:auto;
        min-height:350px;
        display:block;
        margin:0 auto;
        @media(max-width:768px){
          min-height:0;
          width:auto;
        }
      }
    }
    .entry-content{
        background:white;
        padding:25px 50px;
    }
  }

}

.child-with-image{
  .page-article {
    .entry-content {
      padding:25px;
    }
  }
}

