.post-type-archive-match{
    .bar{
        text-transform:uppercase;
        margin-bottom: 50px;

        .custom-wrapper{
            display:flex;
            align-items: center;
            justify-content: flex-end;

            @media(max-width:768px){
                flex-direction: column;
            }

        }

        .styled-select{
            margin-left:25px;
            border:none;
            width:320px;

            @media(max-width:768px){
                width:100%;
                margin-left:0;
                margin-top:25px;
            }

            select{
                background:$newLightBlue;
                border:none;
                font-size:30px;
                height:auto;
                padding:20px;
                width:100%;
                color:white;
            }
            &:after{
                top:50%;
                transform:translateY(-50%);
                font-size:32px;
            }
        }

        p{
            margin-bottom:0;
            a{
                text-decoration: underline;
                color:white;
                font-weight:700;
                display:inline-block;
                margin-right:25px;
            }
            span{
                a{
                    text-decoration: none;
                }
            }
        }

    }
}
