.custom-flexible-section{
    &.image-banner-section{
        a{
            display:block;
        }
        img{
            width:100%;
            height:auto;
        }
    }
}
