// IE9 and below
body.ie9,
body.ie8{
        
    .ad-wrapper{
        img{
            top:auto;
            left: auto;
            right: auto;
            bottom: auto;
        }
    }
    .dfp-container{
        margin-left: -150px;
        margin-top: -125px;
    }
        footer.content-info{
        .partners{
            ul{
                display: block;
                text-align: center;
                li{
                    @media (min-width:$screen-md-min){
                        display: inline-block;
                        max-width: 11.15%;
                    }
                    img{
                           
                    }
                }
            }
        }
    }
}


// IE9 only
body.ie9{

}
 // IE8 and below only
body.ie8{
    .category-header{
        margin-left: 0;
        @media (min-width:$screen-md-min){
            margin-left:50px;
        }
    }
    a.cover-link + .img-caption{
        background: #000;
    }
    
    
    
    //match tables
    
    @media (min-width:$screen-md-min){
    .table{
        table-layout: fixed;
    }
    .table.fixture-table tr{
        >td:first-child{
            width: 180px;
        }
        >td+td{
            width: 20%;
        }
        >td+td+td+td+td+td+td+td{
            width: 17%;
        }
        >td+td+td{
            text-align: right;
            width: 10%;
        }
        >td+td+td+td+td+td+td{
            width: 10%;
        }
        >td+td+td+td,td+td+td+td+td+td{
            width: 70px;
        }
      
    }
}

@media (max-width: $screen-lg-min - 1){

	.table.fixture-table tr>td:last-child{
    	text-align: center;
	}
	
}

@media (max-width: $screen-md-min - 1){
    
   
	.table.fixture-table tr>td:first-child {
		text-align:right;
		width: 50%;
	}
	.table.fixture-table tr>td+td {
		text-align:left;
		width: 50%;
	}
	.table.fixture-table tr>td+td+td {
		text-align: right;
	}
	.table.fixture-table tr>td+td+td,.table.fixture-table tr>td+td+td+td,.table.fixture-table tr>td+td+td+td+td,.table.fixture-table tr>td+td+td+td+td+td,.table.fixture-table tr>td+td+td+td+td+td+td {
		font-size:14px;
		width:32%;
	}
	.table.fixture-table tr>td+td+td+td,.table.fixture-table tr>td+td+td+td+td+td {
		text-align:center;
		width:12%;
		overflow:hidden;
		padding:0;
	}
	
	.table.fixture-table tr>td+td+td+td+td+td>img,.table.fixture-table tr>td+td+td+td+td+td>img {
		//max-height:30px;
	}
	
	.table.fixture-table tr>td+td+td+td+td {
		width:12%;
		min-width:20px;
	}
	
	.table.ministandings.fixture-table tr>td {
		float:none;
		display:table-cell;
	}
	
	.table.ministandings.fixture-table tr>td:first-child {
		text-align:center;
	}
	
	.table.ministandings.fixture-table tr>td+td {
		text-align:left;
	}
	
	
}

@media (max-width: $screen-xxs-min - 1){
    .table.fixture-table tr>td:first-child,
    .table.fixture-table tr>td+td,
    .table.fixture-table tr>td+td+td+td+td+td+td+td,
    .table.fixture-table tr>td:last-child {
		text-align:center;
		width: 100%;
	}
	.table.fixture-table tr>td img {
		max-width:88%;
		margin-top: 4px;
	}
	.table.fixture-table tr>td+td+td,.table.fixture-table tr>td+td+td+td,.table.fixture-table tr>td+td+td+td+td,.table.fixture-table tr>td+td+td+td+td+td,.table.fixture-table tr>td+td+td+td+td+td+td {
		font-size:13px;
		line-height: 2.5em;
    }
}
    
    
    
    
}
