.ms-form-element {
    margin: 0px;
    margin-bottom: 20px;
    
    label, .wpmui-field-label {
        display: block;
        font-weight: bold;
        font-size: 13px;
        line-height: 1.4em;
        margin: 0 0 13px;
    }
    input, .wpmui-field-input {
        display: block; float: none;
        width: 100%;
        padding: 3px 5px;
        resize: none;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.4;
    }
}
.wpmui-field-input.button {
    @extend .btn;
}
.wpmui-field-input.button.button-primary {
    @extend .btn-primary;
}
.wpmui-link {
    @extend .btn;
    @extend .btn-default;
}
.ms-login-form {
    @extend .panel;
    @extend .panel-default;
    .nav {
        margin-bottom: 10px;
        a {
            @extend .btn;
            @extend .btn-default;
        }
    }
    .button-primary {
        @extend .btn;
        @extend .btn-primary;
    }
}
p.login-remember {
    label {
        display: inline;
    }
    margin-bottom: 10px;
}

.ms-field input[type=text], .ms-field input[type=password] {
    @extend .form-control;
}

.ms-form.ms-no-labels p {
    text-align: left;
}

.ms-purchase-table {
    @extend .table;
    @extend .table-bordered;
    .ms-title-column {
        font-weight: bold;
    }
}
.ms-account-wrapper table {
    @extend .table;
    @extend .table-bordered;
}