@-webkit-keyframes fade-move-left {
    0% {opacity:0;transform:translate(200px,0); }
    70% {opacity:0.7;transform:translate(0,0); }
    70% {opacity:1;transform:translate(0,0); }
}
@keyframes fade-move-left {
    0% {opacity:0;transform:translate(200px,0); }
    70% {opacity:0.7;transform:translate(0,0); }
    70% {opacity:1;transform:translate(0,0); }
}
@-webkit-keyframes fade-move-up {
    0% {opacity:0;transform:translate(0,10px); }
    70% {opacity:0.7;transform:translate(0,0); }
    70% {opacity:1;transform:translate(0,0); }
}
@keyframes fade-move-up {
    0% {opacity:0;transform:translate(0,10px); }
    70% {opacity:0.7;transform:translate(0,0); }
    70% {opacity:1;transform:translate(0,0); }
}
@-webkit-keyframes fade {
    0% {opacity:0; }
    70% {opacity:0.7;}
    100% {opacity:1; }
}
@keyframes fade {
    0% {opacity:0; }
    70% {opacity:0.7; }
    100% {opacity:1;}
}

.custom-flexible-section{
    &.hero-section{
        padding:48px 0;
        position:relative;

        .dundee-fc-hero-logo {
            -webkit-animation: fade-move-up 1.5s ease-in-out forwards;
            animation: fade-move-up 1.5s ease-in-out forwards;
        }

        .banner-100{
            -webkit-animation: fade 5s ease-in-out forwards;
            animation: fade 5s ease-in-out forwards;
            position: absolute;
            top: 0;
            z-index: -1;
            max-width: 100%;
            width:100%;
            height:100%;
        }


        .image-wrapper-mobile{
            margin-bottom:48px;

            @media(min-width:993px){
                display:none;
            }

            .fc{
                justify-content: center;
                display:flex;
                align-items: center;
                .text{
                    font-weight:800;
                    text-transform:uppercase;
                    color:$white;
                    font-size:48px;
                }
                svg{
                    margin-left:15px;
                    height:50px;
                }
            }
        }

        .info-wrapper{
            display:flex;
            justify-content: space-between;
            @media(max-width:992px){
                flex-direction: column-reverse;
            }

            .primary-wrapper{
                width:30%;

                @media(max-width:992px) {
                    margin-top: 30px;
                }

                .image-wrapper{
                    margin-bottom:48px;
                    @media(max-width:992px){
                        display:none;
                    }
                    .fc{
                        justify-content: center;
                        display:flex;
                        align-items: center;
                        .text{
                            font-weight:800;
                            text-transform:uppercase;
                            color:$white;
                            font-size:48px;
                        }
                        svg{
                            margin-left:15px;
                            height:50px;
                        }

                        img {
                            width:260px;
                        }
                    }
                }

                .next-matches-wrapper{
                    color:$newLightBlue;
                    text-transform:uppercase;
                    font-family:"Zooja", sans-serif;
                    font-size:40px;
                    text-align:center;
                    border-bottom: 1px solid rgba(255,255,255,.35);
                    margin-right:30px;
                }

                .matches-wrapper{
                    display:flex;
                    justify-content: center;

                    @media(max-width:992px) {
                        margin-bottom: 30px;
                    }

                    @media(max-width:576px){
                        flex-direction: column;
                    }

                    .match-wrapper{
                        margin-right:30px;
                        padding-right:30px;
                        padding-top:15px;

                        @media(max-width:1600px){
                            margin-right:15px;
                            padding-right:15px;
                        }

                        border-right:1px solid rgba($white, 0.35);
                        padding-bottom:15px;

                        @media(max-width:576px){
                            border-right:0;
                            padding-right:0;
                            margin-right:0;
                            border-bottom:1px solid rgba($white, 0.35);
                        }

                        &:last-child{
                            padding-right:0;
                            border-right:0;
                            @media(max-width:992px){
                                margin-right:0;
                            }
                        }

                        .next-match{
                            color:$newLightBlue;
                            text-transform:uppercase;
                            font-family:"Zooja", sans-serif;
                            font-size:40px;
                            text-align:center;
                        }

                        .versus{
                            text-transform: uppercase;
                            font-weight:700;
                            text-align:right;
                            color:$white;
                            margin-bottom:15px;
                        }
                        .ctas{
                            .cta{
                                text-transform:uppercase;
                                margin-bottom:15px;
                                a{
                                    display:block;
                                    text-align:center;
                                    font-weight:700;
                                    padding:15px 0;
                                }

                                &.buy{
                                    position:relative;
                                    @keyframes anim-out-pseudo {
                                        0% {
                                            background-size: 0% 0%;
                                        }
                                        100% {
                                            background-size: 200% 200%;
                                        }
                                    }

                                    a{
                                        background:$newGold;
                                        color:black;
                                        transition:0.3s;

                                        background-image: radial-gradient(circle at center, rgba(#ffc658, 1) 50%, transparent 50%);
                                        background-position: 50% 50%;
                                        background-repeat: no-repeat;
                                        background-size: 100% 100%;
                                        transition: background-size 0.4s, color 0.4s;
                                        background-size: 0% 0%;
                                        animation: anim-out-pseudo 1.75s infinite;

                                        &:hover{
                                            opacity:1;
                                            background: #ffc658;
                                            text-decoration: none;
                                        }
                                    }
                                }
                                &.match-info{
                                    margin-bottom:0;
                                    a{
                                        background:rgba($newMainBlue, 0.5);
                                        color:$newLightBlue;
                                        border:2px solid rgba(#6B7687, 0.6);
                                        transition:0.3s;
                                        &:hover{
                                            text-decoration: none;
                                            background:rgba($newMainBlue, 1);
                                        }
                                    }
                                }

                            }

                            .match-details{
                                background:$newLightBlue;
                                color:$white;
                                font-size:12px;
                                padding:10px 15px;
                                @media(max-width:1600px){
                                    padding:10px;
                                }
                                font-weight:700;
                                margin-bottom:15px;
                                span{
                                    color:$newMainBlue;
                                }
                                .bottom{
                                    display:flex;
                                    flex-wrap: wrap;
                                    .date{
                                        margin-right:15px;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .secondary-wrapper{
                position:relative;
                -webkit-animation: fade-move-left 4s ease-in-out forwards;
                animation: fade-move-left 4s ease-in-out forwards;
                @media(min-width:993px) {
                    flex-basis: 75%;
                }
                @media(min-width:1601px) {
                    flex-basis: 67%;
                }
                .image-wrapper{
                    height:530px;
                    position:relative;
                    overflow: hidden;

                    img{
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                        object-fit: cover;
                        transition:0.3s;
                    }



                }

                a{
                    display:block;

                    &:before{
                        content:"";
                        height:50%;
                        width:100%;
                        position:absolute;
                        background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(35,58,90,1));
                        bottom:0;
                        left:0;
                        z-index:2;
                        transition:0.3s;
                    }

                    &:hover{
                        &:before {
                            height: 60%;
                        }
                        img{
                            filter:brightness(105%);
                            transform:scale(1.1);
                        }
                    }


                }

                .content-wrapper{
                    position:absolute;
                    bottom:30px;
                    left:30px;
                    right:30px;
                    z-index:10;
                    width:calc(100% - 60px);
                    color:$white;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;

                    @media(max-width:1500px){
                        flex-direction: column;
                    }

                    .date{
                        text-transform:uppercase;
                        font-weight:800;
                    }
                    .title{
                        text-transform:uppercase;
                        font-size:48px;
                        font-weight:800;
                        line-height:1;

                        @media(max-width:576px){
                            font-size:32px;
                        }
                    }
                    .description-wrapper{
                        border-left:2px solid $newLightBlue;
                        padding-left:15px;
                        margin-left:15px;
                        font-weight:700;
                        font-size:20px;
                        width: 75%;

                        @media(max-width:576px){
                            font-size:16px;
                        }

                        @media(max-width:1500px){
                            margin-left:0;
                            margin-top:15px;
                        }

                        .read-article{
                            text-transform: uppercase;
                            color:$newGold;
                        }
                    }
                }
            }

        }



    }
}
