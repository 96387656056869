.team-custom-filter-bar{
    ul{
        padding:20px 20px 10px 10px;
        text-transform:uppercase;
        text-align:right;

        @media(max-width:576px){
            text-align:center;
        }

        li{
            display:inline-block;
            list-style-type:none;
            margin-left:25px;
            margin-bottom:10px;
            a{
                border-bottom: 1px solid transparent;
                text-decoration: none;
                &.active{
                    text-decoration: none;
                    font-weight:800;
                    border-bottom: 1px solid $white;
                }
                color:white;
            }
        }
    }
}

.team-archive-item{
    margin-bottom:50px;

    @media(max-width:475px){
        width:100% !important;
        max-width:100% !important;
    }

    .image-wrapper{
        height:0;
        padding-bottom:130%;
        position:relative;

        .player-number{
            position:absolute;
            bottom:0;
            right:0;
            z-index:100;
            color:$newLightBlue;
            font-family:"Zooja", sans-serif;
            font-size: 150px;
            line-height: 0.6;
        }

        img{
            height:100%;
            width:100%;
            object-fit: cover;
            object-position: top;
            position:absolute;
            top:0;
            left:0;
        }
    }

    .content-wrapper{
        color:white;
        a{
            color:white;
            &.profile-link{
                color:$newGold;
                text-transform: uppercase;
                font-size:16px;
                font-weight:800;
            }
        }

        h2{
            text-transform:uppercase;
            font-weight:800;
            margin-top:10px;
            margin-bottom:0;
            @media(max-width:768px){
                font-size:24px;
            }
        }

        .info-wrapper{
            display:flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: flex-end;

            .post-category--team{
                font-size:23px;
                font-weight:800;
                margin-right:10px;
                @media(max-width:768px){
                    font-size:16px;
                }
            }
        }
    }

    &.person{
        min-height:592px !important;
    }

}


.custom-team-single{

    .top-section{
        display:flex;
        @media(max-width:768px){
            flex-direction: column;
        }

        .primary-section{
            width:50%;
            display:flex;
            align-items: center;
            justify-content: center;
            img{
                max-width:100%;
            }
            @media(max-width:768px){
                width:100%;
                img{
                    max-width:60%;
                    display:block;
                }
            }
        }
        .secondary-section{
            width:50%;
            @media(max-width:768px){
                width:100%;
            }

            .title-section{
                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                h1{
                    text-transform:uppercase;
                    word-spacing: 100vw;
                    font-size:90px;
                    font-weight:800;
                    line-height:0.9;
                    color:white;
                    margin-top:0;

                    @media(max-width:768px){
                        padding-left:25px;
                        margin-top:25px;
                    }

                    @media(max-width:1400px){
                        font-size:70px;
                    }

                    @media(max-width:992px){
                        font-size:50px;
                    }

                    @media(max-width:576px){
                        font-size:38px;
                    }


                }
                .shirt-number{
                    font-family:"Zooja", sans-serif;
                    font-size:15vw;
                    color:$newLightBlue;
                    line-height: 0.7;
                    transform:translate(-15%, 50%);
                    padding-right:25px;
                    @media(max-width:768px){
                        font-size:20vw;
                    }
                }
            }

            .player-information{
                background:rgba(#081C40, 0.8);
                color:white;
                display:flex;
                align-items: center;
                padding:50px;

                @media(max-width:1300px){
                    padding:25px;
                }

                @media(max-width:1300px){
                    flex-direction: column;
                }

                justify-content: space-between;
                .player-info{
                    width:380px;
                    @media(max-width:1300px){
                        width:100%;
                        margin-bottom:25px;
                    }
                    h3{
                        text-transform:uppercase;
                        font-weight:800;
                        margin-top:0;
                    }
                    ul{
                        padding-left:0;
                        margin-bottom:0;
                        li{

                            &:first-child{
                                border-top:1px solid white;
                            }

                            display:flex;
                            justify-content: space-between;
                            border-bottom:1px solid white;
                            font-size:20px;

                            @media(max-width:1400px){
                                font-size:16px;
                            }

                            padding:15px 0;
                            .info-label{
                                font-weight:700;
                            }
                        }
                    }
                }

                .controls{
                    display:flex;
                    justify-content: center;
                    text-transform:uppercase;
                    text-align: center;
                    flex-wrap: wrap;



                    a{

                        color:white;
                        font-weight:800;
                        display:inline-block;
                        margin:10px;

                        .fa{
                            color:$newGold;
                            margin:0 5px;
                        }
                    }
                }



            }
        }
    }

    .bio-section{
        color:white;
        padding:50px 0;
        overflow: hidden;

        .image-col{
            display:flex;
            align-items: center;
            .image-wrapper{
                position:relative;
                img{
                    max-width:100%;
                    padding-right:50px;
                    @media(max-width:992px){
                        padding-right:0;
                    }
                }
            }
        }

        a{
            display:block;
            height:100%;
            width:100%;
            &:hover{
                .overlay-image{
                    transform:translate(calc(-50% - 25px),-50%) scale(1.1);
                    @media(max-width:992px){
                        transform:translate(-50%,-50%) scale(1.1);
                    }
                }
                .gold-overlay-button{
                    background: #ffc658;
                }
            }
        }

        .gold-overlay-button{
            position:absolute;
            bottom:10%;
            left:50%;
            transform:translateX(calc(-50% - 25px));
            background: $newGold;
            padding:1rem 3rem;
            text-transform: uppercase;
            color: #000;
            font-weight:700;
            transition:0.3s;
            @media(max-width:992px){
                transform:translateX(-50%);
            }
        }

        .overlay-image{
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(calc(-50% - 25px),-50%);
            transition:0.3s;
            @media(max-width:992px){
                transform:translate(-50%,-50%);
            }
            img{
                padding-right:0 !important;
            }
        }

        .bio-col{
            display:flex;
            align-items: center;

            .bio-wrapper{
                position:relative;

                .sponsor-block{
                    position:relative;
                    z-index:100;
                    margin-bottom:1rem;
                    .sponsor{
                        text-transform:uppercase;
                        color:$white;
                        display:block;
                        padding:10px;
                        border:1px solid $white;
                        font-weight:700;

                        &.away-sponsor{
                            background:$newLightBlue;
                            color:$newMainBlue
                        }
                        &.home-sponsor{
                            background:$newMainBlue;
                        }
                    }
                }

                &:before{
                    content:"";
                    background-size:20%;
                    background: radial-gradient(#192c4b 0%, rgba(25, 44, 75, 0.48) 56.85%, rgba(25, 44, 75, 0) 70%);
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translate(-50%, -50%);
                    height:135%;
                    width:135%;
                    z-index:0;
                }
            }

            h2{
                text-transform: uppercase;
                font-size:60px;
                font-weight:800;
                position:relative;
                @media(max-width:1400px){
                    font-size:50px;
                }
            }
            .description{
                font-size:22px;
                position:relative;
                @media(max-width:1400px){
                    font-size:18px;
                }
            }
        }
    }
}

.advert-section{
    padding:250px 0;
    background-size:100% auto;
    background-repeat:no-repeat;
    width:100%;

    @media(max-width:992px){
        padding:100px 0;
    }
}
