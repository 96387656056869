.custom-flexible-section {
    &.news-carousel-section {
        padding:48px 0;
        overflow: hidden;

        .adverts-active-swiper{
            clip-path: inset(-100vw -100vw -100vw 0);
        }

    }
}
