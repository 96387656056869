footer{
    background:$newMainBlue;
    color:$white;
    .custom-wrapper{
        padding-top:32px;
        padding-bottom:32px;


        .menu-wrapper{
            display:flex;
            justify-content: center;
            ul{
                margin-bottom:0;
                padding-left:0;
                text-align:center;
                li{
                    margin-right:2rem;
                    display:inline-block;
                    text-transform:uppercase;
                    font-weight:700;
                    .my-dropdown-toggle{
                        display:none;
                    }
                    a{
                        color:$white;
                    }
                    .sub-menu{
                        display:none;
                    }
                }
            }
        }

        .social-wrapper{
            display:flex;
            align-items: center;
            justify-content: center;
            padding:32px 0;
            ul{
                padding-left:0;
                margin-bottom:0;
                li{
                    list-style-type:none;
                    font-size:32px;
                    display:inline-block;
                    margin:0 16px;
                    a{
                        color:$newLightBlue;
                        transition:0.3s;
                        &:hover{
                            color:$newGold;
                        }
                    }
                }
            }
        }
    }

    .bottom-row{

        font-size:16px;

        .container-fluid{
            padding:0 !important;
        }

        .row{
            display:flex;
            align-items: flex-end;
            @media (max-width:$screen-md-min){
                flex-direction: column;
            }
        }

        ul{
            padding-left:0;
            margin-bottom:0;
            li{
                list-style-type: none;
                a{
                    color:$white;
                }
            }
        }

        .info-col{
            .custom-wrap{
                display:flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
                flex-direction: column;
                @media(min-width:576px) {
                    flex-direction: row;
                    text-align: left;
                    justify-content: space-between;
                }
            }
        }

        .doc-col{
            @media (max-width:$screen-md-min){
                margin:2rem 0;
            }
            ul{
                text-align: center;
                li{
                    display:inline-block;
                    margin-right:2rem;
                    &:last-child{
                        margin-right:0;
                    }
                }
            }
        }

        .website-by-col{
            .custom-wrap{
                display:flex;
                justify-content: flex-end;
                @media (max-width:$screen-md-min){
                    justify-content: center;
                }
                a{
                    color:$white;
                    display:flex;
                    align-items: flex-end;
                    p{
                        margin-bottom:0;
                        margin-right:1rem;
                    }
                }
            }

        }
    }
}
