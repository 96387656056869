.custom-categories{
    padding:32px 0;
    ul{
        margin-bottom:0;
        padding-left:0;
        li{
            list-style-type:none;
            display:inline-block;
            margin-right:32px;
            &:last-child{
                margin-right:0;
            }
            a{
                color:$newLightBlue;
            }
        }
    }
}


.archive.category, .blog{
    .top-section{
        background:$white;
        padding-bottom:50px;
        @media(max-width:992px){
            background-size:cover;
        }
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    .top-section{

    }

    .image-wrapper{
        position:relative;
        .post-category{
            position:absolute;
            top:10px;
            left:10px;
            padding:10px;
            background:$newLightBlue;
            color:$white;
            font-weight:900;
            font-size:11px;
        }
    }

    .pagination{
        .page-numbers {
            color:$white;
            &.current, &.next, &.prev{
                background:$newGold;
                color:black;
                border:1px solid $newGold;
            }
        }
    }

    .standard-post-output{
        padding:48px 0;
    }
}


.custom-featured-post{
    padding-top:50px;
    padding-bottom:32px;

    .post-category{
        text-transform: uppercase;
    }

    .content-wrapper{
        h2{
            margin-top:0;
            text-transform:uppercase;
            color:$newDarkBlue;
            font-weight:800;
            font-size:40px;
            margin-bottom:15px;
        }
        .post-date{
            font-weight:700;
            display:block;
            margin-bottom:15px;
            color:$newDarkBlue;
        }
        .post-excerpt{
            font-weight:700;
            color:$newDarkBlue;
            max-width:70%;
            font-size:22px;
        }
        .read-more{
            display:inline-block;
            margin-top:20px;
            background:$newGold;
            padding:10px 20px;
            text-transform:uppercase;
            font-weight:800;
        }
    }
}

.standard-post{
    margin-bottom:48px;
    position:relative;
    .image-wrapper{
        height:0;
        position:relative;
        padding-bottom:72%;
        overflow: hidden;

        .post-category{
            position:absolute;
            top:10px;
            left:10px;
            padding:10px;
            background:$newLightBlue;
            color:$white;
            text-transform:uppercase;
            font-weight:900;
            font-size:11px;
            z-index:10;
        }

        img{
            width:100%;
            height:100%;
            object-fit: cover;
            max-width:100%;
            transition:0.3s;
        }

        a{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            &:hover{
                img{
                    filter:brightness(105%);
                    transform:scale(1.1);
                }
            }
        }


    }

    .content-wrapper{
        color:$white;
        text-transform:uppercase;
        h2{
            margin-top:0;
            font-weight:700;
            font-size:16px;
            margin-bottom:0;
        }
        .post-date{
            font-size:14px;
            font-weight:700;
            margin-top:10px;
            display:block;
        }
        a.read-more{
            color:$newGold;
        }

        .inner-wrap{
            display:flex;
            justify-content: space-between;
            align-items: flex-end;
        }

    }
}

.single-post {
    background-repeat: no-repeat;
    background-attachment: fixed;
    .single-post-holder {

        background-size:100% auto;
        background-repeat:no-repeat;
        background-attachment: fixed;

        .standard-post-output{
            padding:48px 0;
            .entry-content{
                background:white;
                padding:15px 0;
            }
            h1{
                text-transform:uppercase;
                font-size:42px;
                color:$newMainBlue;
                margin-bottom:0;
                line-height:inherit;
                font-weight:800;
                margin-top:0;
            }
            .primary-post-date{
                font-weight:700;
                margin-bottom:32px;
            }
        }
    }

    .custom-categories{
        ul{
            li{
                opacity:0.25;
                &.active{
                    opacity:1;
                }
            }
        }
    }

    .custom-related-news{
        clear:both;
        margin-top:50px;
        h2.related-title{
            font-weight:800;
            text-transform:uppercase;
            color:$newLightBlue;
            font-family:"Zooja", sans-serif;
            font-size:40px;
            transform: translateX(-10px) rotate(-10deg);
            transform-origin: bottom left;
        }

        .standard-post{
            .content-wrapper{
                color:$newDarkBlue;
            }
        }
    }
}

