.custom-flexible-section {
    &.featured-news-section {
        display:flex;
        height:700px;


        .standard-post{
            .image-wrapper{
                overflow: hidden;
                background-color: white;
                a{
                    img{
                        transition:0.3s;
                    }
                    &:hover{
                        img{
                            transform:scale(1.1);
                        }
                    }
                }
            }
        }


        @media(max-width:992px){
            height:auto;
            flex-direction: column;
        }

        .advert-wrapper, .article-wrapper{
            width:50%;
            height:100%;
            @media(max-width:992px){
                width:100%;
                height:500px;
            }
            @media(max-width:576px){
                height:400px;
            }
            img,video{
                max-width: 100%;
            }
            a{
                display:block;
                height:100%;
                width:100%;
            }
        }

        .advert-wrapper{

            a{
                &:hover{
                    img{
                        filter:brightness(80%);

                    }
                }
            }

            video{
                height:100%;
                width:100%;
                object-fit: cover;
            }
            .image-holder{
                height:100%;
                position:relative;
                overflow: hidden;
                img{
                    height:100%;
                    width:100%;
                    object-fit: cover;
                    object-position: center;
                    transition:0.3s;
                }
                .title-overlay{
                    font-size:48px;
                    font-weight:800;
                    color:$white;
                    z-index:10;
                    bottom:30px;
                    right:30px;
                    position:absolute;
                    line-height:1;
                    left:30px;
                    width:calc(100% - 60px);
                    text-align:right;
                    @media(max-width:576px){
                        font-size:32px;
                    }
                }
            }
        }

        .article-wrapper{
            position:relative;
            .image-wrapper{
                position:relative;
                height:100%;
                img{
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    object-fit: cover;
                    transition:0.3s;
                }
            }

            a{
                display:block;
                &:before{
                    content:"";
                    height:50%;
                    width:100%;
                    position:absolute;
                    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(35,58,90,1));
                    bottom:0;
                    left:0;
                    z-index:2;
                    transition:0.3s;
                }
                &:hover{
                    &:before{
                        height:80%;
                    }
                    img{
                        filter:brightness(105%);
                    }
                }
            }

            .content-wrapper{
                position:absolute;
                bottom:30px;
                left:30px;
                right:30px;
                z-index:10;
                width:calc(100% - 60px);
                color:$white;
                display:flex;
                justify-content: space-between;
                align-items: center;

                @media(max-width:1500px){
                    flex-direction: column;
                }

                .date{
                    text-transform:uppercase;
                    font-weight:800;
                }
                .title{
                    text-transform:uppercase;
                    font-size:48px;
                    font-weight:800;
                    line-height:1;
                    @media(max-width:576px){
                        font-size:32px;
                    }
                }
                .description-wrapper{
                    border-left:2px solid $white;
                    padding-left:15px;
                    margin-left:15px;
                    font-weight:700;
                    font-size:20px;
                    width:50%;

                    @media(max-width:576px){
                        font-size:16px;
                    }

                    @media(max-width:1500px){
                        width:100%;
                        margin-left:0;
                        margin-top:15px;
                    }

                    .read-article{
                        text-transform: uppercase;
                        color:$newGold;
                    }
                }
            }
        }

    }
}
