.side-bar, .filter-bar{
   float: left;
   @media (max-width: $screen-sm-min - 1){
       width: 100%;
   }
    h3{
        font-weight: 200;
        margin-top: 0;
    }
    color: #fff;
    a, p, li, span{
        color: #fff;
        font-weight: 300;
    }
    ul{
        list-style: none;
        padding-left: 0;
        li{
            &.active a{
                font-weight: 700;
            }
            a{
                display: block;
                font-size: 18px;
                transition: .25s all ease;
                padding-left: 0;
                &:hover{
                    padding-left: 5px;
                    text-decoration: none;
                }
                
            }
        }
    }
    .teams{
        z-index: 10 !important;
    }
    .teams.scroll-to-fixed-fixed{
        background: $brand-primary;
        box-shadow: 0  ( - $grid-gutter-width / 2 ) 0 ( $grid-gutter-width / 2 ) $brand-primary;
       // margin-top: 65px;
        z-index: 10 !important;
        transition: .25s all ease;

            .team-badge {
                max-height: 50px; 
            }
            .vs {
                padding-top: 4%; 
            }
    }
    /*.teams.scroll-to-fixed-fixed.mini{
        margin-top: -35px;
    }*/
    aside{
        border-top: 1px solid  darken($brand-primary,10%);
        padding: 45px 30px 40px 50px;
    }
    .archive{
        >ul{
            >li,>li>a{
                font-weight: 800;
            }
            ul{
                 margin-bottom: 15px;
            }
        }
    }
    
    .ads{
       // margin-bottom: 10px;
    }
    .ad-wrapper, .dfp-wrapper{
       // margin-top: 10px;
        display: block;
    }
    .dfp-wrapper{
        background: $brand-primary;
    }

    
    .match{
        padding: $grid-gutter-width;
        .match-heading{
            font-weight: 800;
            padding-bottom: 15px;
            p{
                padding-top: 15px;
                font-weight: 800;
            }
        }
        .match-footer 
        {
            border-top: 1px solid;
            overflow: hidden;
            padding-top: 10px;
            p{
                float: left;
                padding-right: 6px;
                font-size: 12px;
                @media (min-width:$screen-sm-min){
                    font-size: 14px;
                }
            }
        }
    }
}
.darken{
    background: darken($brand-primary,10%);
}

