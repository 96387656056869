.custom-search-form{
    padding:15px 0;
    display:flex;
    justify-content: center;
    margin-top: -105px;
    transition:0.3s;

    &.active{
        margin-top:0;
    }
    .custom-wrapper{
        width:100%;
    }

    form{
        width:80%;
        margin:0 auto;

        @media(max-width:576px){
            width:100%;
        }

        input[type="search"]{
            padding:10px 20px;
            border:none;
            width:50%;
            @media(max-width:576px){
                width:auto;
                padding:10px;
            }
            box-shadow:none;
            &:focus, &:active, &:hover{
                outline:none;
            }
        }
        button{
            padding:10px 20px;
            @media(max-width:576px){
                padding:10px;
            }
            background:$newGold;
            color:black;
            text-transform:uppercase;
            font-weight:800;
            border:none;
            box-shadow:none;
        }
    }
}


header{
    background:$newDarkBlue;
    width:100%;
    border-top:8px solid $newLightBlue;

    .logo{
        padding: 10px 0 10px;
        height:106.65px; //needed due to logo being hidden on homepage

        @media (min-width:1100px) {
            margin-right: 16px;
        }
    }

    #main-primary-nav, #main-secondary-nav{
        @media(max-width:992px){
            display:none;
        }

        .menu-item {
            position: relative;
            a {
                color:white;

                &:hover {
                    text-decoration: none;
                    color:#3DBFEF;
                }
            }
        }
    }

    .header-wrapper{
        position: relative;
        display:flex;
        justify-content: space-between;
        width:100%;

        padding-left:10px;
        padding-right:10px;
        @media (min-width:$screen-sm-min){
            padding-left:35px;
            padding-right:35px;
        }
        @media (min-width:1400px){
            padding-left:120px;
            padding-right:120px;
        }


        ul{
            padding-left:0;
            margin-bottom:0;
            li{
                display:inline-block;
                list-style-type:none;
                a{
                    color:$white;
                }
            }
        }

        .primary-nav-wrapper{
            display:flex;
            text-transform:uppercase;
            align-items: center;
            width:100%;
            .header-svg{
                height:81px;
                margin-right:32px;
                padding-top:1rem;
            }
            li{
                font-size:18px;
                margin-right: 10px;
                @media (min-width:1100px) {
                    font-size:20px;
                    margin-right: 16px;
                }
                @media(min-width:1400px) {
                    font-size: 24px;
                    margin-right:24px;
                }
                font-weight:700;
                &:last-child{
                    margin-right:0;
                }
            }

            .main-nav-wrapper {
                width:100%;
                margin-right:20px;

                @media(max-width:1200px){
                    display:none;
                }

                .mega-sub-menu {
                    top:70px !important;

                    .mega-block-title {
                        font-size:40px !important;
                    }

                    .mega-sub-nav-wrap-1, .mega-sub-nav-wrap-2 {
                        border-left:1px solid !important;
                    }

                    .mega-menu-row {
                        .mega-sub-menu {
                            top:0 !important;

                            .mega-menu-item {
                                padding-left:10px;
                            }
                        }
                    }
                }

                .mega-indicator {
                    display: none !important;
                }
            }
        }

        .secondary-nav-wrapper{
            display:flex;
            text-transform:uppercase;
            align-items: center;
            ul{
                margin-right:32px;
            }
            li{
                font-size: 14px;
                margin-right: 10px;
                @media (min-width:1100px) {
                    font-size: 16px;
                    margin-right: 16px;
                }
                @media(min-width:1400px) {
                    font-size:18px;
                    margin-right: 32px;
                }
                font-weight:700;
            }

            .dfc-logo {
                width:70px;
                padding:10px;

                @media (max-width:$screen-sm-min){
                    display:none;
                }
            }

            .donate-button {
                background-color:#3DBFEF;
                color:white;
                border-radius: 25px;
                margin-right:10px;
                border-color: #3DBFEF;
                font-weight:bold;

                @media (max-width:$screen-sm-min){
                    display:none;
                }

                &:hover {
                    background-color:white;
                    color:#3DBFEF;
                }
            }

            .toggles{
                display:flex;
                height:100%;
                .menu-toggle{
                    height:100%;
                    width:87.28px;
                    color:$white;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    font-size:24px;
                    transition:0.3s;
                    &.search-toggle{
                        background:$newMidBlue;
                        cursor:pointer;
                        &:hover{
                            opacity:0.8;
                        }
                    }
                    &.hamburger-menu-toggle{
                        border-right:2px solid $newMidBlue;
                        cursor:pointer;
                        .fa-bars{
                            transition:0.3s;
                        }
                        &:hover{
                            .fa-bars{
                                opacity:0.8;
                            }
                        }
                        @media (min-width:$screen-lg-min){
                            display:none;
                        }
                    }

                }
            }
        }
    }
}

.custom-title-wrapper{
    padding: 25px 10px;
    @media (min-width:$screen-sm-min){
        padding: 25px 35px;
    }
    @media (min-width:$screen-lg-min){
        padding: 25px 120px;
    }

    color:$white;
    background:transparent;
    position:relative;
    z-index:10;

    h1, span{
        margin:0;
        font-weight:700;
        text-transform:uppercase;
        font-size:42px;
    }
}

.hamburger-menu{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    max-height:100vh;
    background:#000;
    z-index:10000;
    background:$newDarkBlue;
    border-top:8px solid $newLightBlue;
    display:none;
    overflow: auto;
    justify-content: space-between;
    flex-direction: column;

    &.active{
        display:flex;
    }

    .close-menu{
        height:86px;
        color:white;
        display:flex;
        align-items: center;
        justify-content: space-between;
        font-size:24px;

        .custom-wrapper{
            width:100%;
            display:flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                display:flex;
                align-items: center;
                svg{
                    padding-top:1rem;
                    height: 81px;
                    width:70px;
                }
            }
        }

        .inner{
            cursor:pointer;
            width: 87.28px;
            display:flex;
            justify-content: center;
            .fa-times{
                transition:0.3s;
            }
            &:hover{
                .fa-times{
                    opacity:0.8;
                }
            }
        }
    }

    .menu-wrapper{
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding:50px 0;
    }

    ul{
        padding-left:0;
        margin-bottom:25px;

        &.social{
            text-align:center;
            li{
                display:inline-block;
                margin:0 15px;
            }
        }

        li{
            list-style-type:none;
            text-transform:uppercase;
            font-weight:800;
            text-align:center;
            font-size:24px;
            margin-bottom:10px;
            a{
                color:$white;
            }
        }
    }
}


