$grid-gutter-width: 20px;
// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$screen-md-min:         1024px;
$screen-sm-min:         850px;
$screen-xxs-min:         480px;

// Colors
$brand-primary:         rgb(23,38,67);
$light-blue:            rgb(0,102,153);
$lightest-blue:         rgb(215,226,243);
$link-blue:             rgb(59,115,222);
$dark-blue:           rgb(10,16,19);
$bright-blue:           rgb(169,239,253);
$red:                   rgb(204,0,0);
$deep-red:              rgb(134,18,33);
//$light-red:             desaturate(lighten($red,10%),20%);
$light-red:             lighten(desaturate($red,25%),16%);
$dark-grey:             rgb(14,23,40);
$mid-grey:              rgb(168,168,168);
$light-grey:            rgb(231,231,231);

$twitter-blue:          rgb(42,162,239);


// Fonts

$main-font:             'Lato',arial,sans-serif;


$newDarkBlue: #000223;
$newMidBlue: #233A5A;
$newMainBlue: #081C40;
$newLightBlue: #3DBFEF;
$newGold: #DCB466;
$white: #FFFFFF;
